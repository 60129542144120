import styled from 'styled-components'

const H1 = styled.h1`
  font-size: calc(38px + (17 - 12) * ((100vw - 300px) / (1920 - 300)));
  margin-bottom: 0.25em;
  color:#99784c;
  font-weight:100;
  margin-bottom: 20px;
  font-family:'HelveticaNeue-thin'!important;
  
  & > p {
    color : #000
  }
  
  @media (max-width: 960px) {
    font-size: calc(27px + (17 - 12) * ((100vw - 300px) / (1920 - 300)));
  }
`;

export default H1;
 