import React, { useState } from "react";
import Wrapper from "components/Wrapper";
import GlobalNav from "components/GlobalNav";
import Footer from "../components/Footer";
import Grid from "@material-ui/core/Grid";
import gql from "graphql-tag";
import H1 from "../components/h1";
import { Query } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Button from "../components/Button";
import Checkbox from "@material-ui/core/Checkbox";
import HeaderBar from "../components/HeaderBar";
import parse from "html-react-parser";
import LoadingSpinner from "../components/LoadingSpinner";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import swal from "@sweetalert/with-react";
import bg from "../assets/images/enquiries/bg.png";

const QUERY = gql`
  query {
    enquiry {
      Name
      EnquiriesIntro
      AddressContact
      Disclaimer
      PDPA
      PDPATickbox
    }
  }
`;

const useStyles = makeStyles(() => ({
  buildingContainer: {
    background: `url(${bg}) left top / cover`,
    position: "relative",
  },
  section_Header: {
    textAlign: "center",
    padding: "2% 0",
    "@media (max-width:960px)": {
      marginTop: "40px",
      marginBottom: "15px",
    },
  },
  contactFormWrapper: {
    width: "100%",

    "& table": {
      width: "100%",
    },
  },
  contactHeader: {
    backgroundColor: "#817256",
    width: "100%",
    paddingTop: "5px",
    paddingLeft: "20px",
    paddingBottom: "5px",
    color: "#ffffff",

    "& p": {
      color: "#fff",
      height: "28px",
      paddingLeft: "20px",
    },
    "@media (max-width:960px)": {
      width: "100%",
      "& p": {
        fontSize: "14px!important",
      },
    },
  },
  textField: {
    width: "100%",
    marginBottom: "10px",
  },
  contactDetails: {
    paddingRight: "20px",
    width: "100%",
    "& img": {
      width: "100%",
    },
    "@media (max-width:960px)": {
      paddingRight: "0",
    },
  },
  btnSubmit: {
    float: "right",
  },
  tickboxWrapper: {
    cursor: "pointer",
    "& p": {
      float: "left",
      marginLeft: "2px",
      marginTop: "-9px!important",
    },
  },
  tickboxWrapperError: {
    cursor: "pointer",
    "& p": {
      float: "left",
      color: "red",
      marginLeft: "2px",
      marginTop: "-9px!important",
    },
    "& div": {
      color: "red",
    },
  },
  btn: {
    height: "35px",
    backgroundColor: "transparent",
    fontFamily: "HelveticaNeue-light!important",
    color: "#99784c",
    marginTop: "30px",
    fontWeight: 100,
    border: "1px solid #99784c",
    padding: "0px 30px",
    borderRadius: "0px",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
    transition: "0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    "&:hover": {
      backgroundColor: "#99784c",
      color: "#fff",
      "&::before": {
        right: 0,
        width: "100%",
        left: "auto",
      },
    },
    "&::before": {
      top: "0px",
      width: "0px",
      right: "auto",
      left: "0px",
      zIndex: -1,
      bottom: "0px",
      content: "''",
      position: "absolute",
      background: "#ededed",
      transition: "width 0.2s linear",
    },
  },
  tickbox: {
    float: "left",
    padding: "0px",
    marginTop: 25,
    marginRight: 5,

    "@media (max-width:960px)": {
      marginTop: 20,
    },
  },
  textFieldWrapper: {
    "@media (max-width:960px)": {
      width: "100%",
    },
  },
}));

const Enquiries = () => {
  const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    contact: "",
    email: "",
    message: "",
  });
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const changeHandler = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const changeContact = (e: any) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    if (value.length) {
      const number = parseInt(value);
      setData({ ...data, [e.target.name]: number });
    } else {
      const number = value;
      setData({ ...data, [e.target.name]: number });
    }
    //added else to solve the last digit can't delete issue
  };

  const submitButton = data => {
    if (
      data.firstName === "" ||
      data.lastName === "" ||
      data.contact === "" ||
      data.email === "" ||
      data.message === ""
    ) {
      return;
    }
    if (checked === false) {
      return;
    }
    axios
      .post("https://www.perfect-ten.com.sg/mailer/send", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then(data => {
        swal({
          title: "Thank you for your enquiry",
          text: "Our representative will be in touch shortly.",
          icon: "success",
          button: "CLOSE",
        }).then(() => {
          dispatch(push("/overview"));
        });
        console.log(data);
      })
      .catch(err => {
        swal({
          title: "Something went wrong",
          text: "Please send contact form again later",
          icon: "error",
          button: "CLOSE",
        });
        return null;
      });
  };

  return (
    <>
      <Wrapper>
        <GlobalNav activeItem='enquiries' />
        <HeaderBar />

        <Query query={QUERY}>
          {({ data: { enquiry }, loading }) => {
            if (loading || !enquiry) {
              return <LoadingSpinner />;
            }

            return (
              <>
                <Grid container>
                  <Grid item md={12} className={classes.buildingContainer}>
                    <Container maxWidth='lg' className='containerLast'>
                      <Grid container md={12}>
                        <Grid item md={12} className={classes.section_Header}>
                          <H1>{parse(enquiry.EnquiriesIntro)}</H1>
                        </Grid>
                        <Grid item md={6} className={`contactTable`}>
                          {parse(enquiry.AddressContact)}
                        </Grid>
                        <Grid item md={6}>
                          <div className={classes.contactFormWrapper}>
                            <table>
                              <tbody>
                                <tr>
                                  <td className={classes.contactHeader}>
                                    Register for interest
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <ValidatorForm
                              ref='form'
                              onSubmit={() => {
                                submitButton(data);
                              }}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  md={6}
                                  className={classes.textFieldWrapper}
                                >
                                  <TextValidator
                                    className={classes.textField}
                                    name='firstName'
                                    type='text'
                                    label='First Name'
                                    value={data.firstName}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    onChange={changeHandler}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={6}
                                  className={classes.textFieldWrapper}
                                >
                                  <TextValidator
                                    className={classes.textField}
                                    name='lastName'
                                    type='text'
                                    label='Last Name'
                                    value={data.lastName}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    onChange={changeHandler}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={12}
                                  className={classes.textFieldWrapper}
                                >
                                  <TextValidator
                                    className={classes.textField}
                                    name='contact'
                                    type='tel'
                                    label='Contact No.'
                                    value={data.contact}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    onChange={changeContact}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={12}
                                  className={classes.textFieldWrapper}
                                >
                                  <TextValidator
                                    label='Email'
                                    className={classes.textField}
                                    onChange={changeHandler}
                                    name='email'
                                    value={data.email}
                                    validators={["required", "isEmail"]}
                                    errorMessages={[
                                      "This field is required",
                                      "Email is not valid",
                                    ]}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  md={12}
                                  className={classes.textFieldWrapper}
                                >
                                  <TextValidator
                                    className={classes.textField}
                                    name='message'
                                    multiline
                                    rows={4}
                                    label='Message'
                                    value={data.message}
                                    validators={["required"]}
                                    errorMessages={["This field is required"]}
                                    onChange={changeHandler}
                                  />
                                </Grid>
                              </Grid>

                              <div className='disclaimer'>
                                {parse(enquiry.PDPA)}
                                <br />
                                <Checkbox
                                  className={classes.tickbox}
                                  checked={checked}
                                  color='primary'
                                  onChange={handleChange}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </div>
                              <div
                                className={
                                  checked
                                    ? classes.tickboxWrapper
                                    : classes.tickboxWrapperError
                                }
                                onClick={() => {
                                  checked
                                    ? setChecked(false)
                                    : setChecked(true);
                                }}
                              >
                                <div className='disclaimer'>
                                  {parse(enquiry.PDPATickbox)}
                                </div>
                              </div>
                              <div className={classes.btnSubmit}>
                                <button type='submit' className={classes.btn}>
                                  {" "}
                                  Submit{" "}
                                </button>
                              </div>
                            </ValidatorForm>
                          </div>
                        </Grid>
                      </Grid>
                    </Container>
                  </Grid>

                  <Container maxWidth='lg' className='containerLast'>
                    <Grid item md={12}>
                      <div className='disclaimer'>
                        {parse(enquiry.Disclaimer)}
                      </div>
                    </Grid>
                  </Container>
                </Grid>
              </>
            );
          }}
        </Query>
        <Footer />
      </Wrapper>
    </>
  );
};

export default Enquiries;
