import React from 'react'
import Wrapper from 'components/Wrapper'
import GlobalNav from 'components/GlobalNav'
import gql from "graphql-tag"
import { Query } from 'react-apollo'
import Grid from '@material-ui/core/Grid'
import Footer from '../components/Footer'
import LoadingSpinner from '../components/LoadingSpinner'

import { makeStyles } from '@material-ui/core/styles'
import H1 from '../components/h1'
import Container from '@material-ui/core/Container'
import HeaderBar from '../components/HeaderBar'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import parse from 'html-react-parser'
import bg from '../assets/images/aboutus/bg.png'
import building_mobile from '../assets/images/aboutus/building_mobile.png'

const QUERY = gql`
  query {
    aboutUs {
      Name
      HeaderImage {
        url
      }
      HeaderTitle
      HeaderDescription
      CKAssetsHoldingLimited
      ArchitectDesignStatement
      AwardsRecognition
      LandscapeDesignStatement
      AwardsRecognitionSaladDressing
    }
  }
`;

const useStyles = makeStyles(() => ({
    bgContainer: {
        position:'relative'
    },
    section1_bg : {
        width:'100%',
        height:'auto'
    },
    sectionBg : {
        background: `url(${bg}) left top / cover`,
        minHeight: '100vh',
        position:'relative',
        '@media (max-width:960px)': { 
            minHeight:'auto'
        }
    },
    buildingContainer : {
        background: `url(${bg}) left top / cover`,
        minHeight:'100vh',
        position:'relative',
        '@media (max-width:960px)': { 
            minHeight:'auto',
        }
    },
    buildingImgContainer: {
        position:'relative',
        width:'100%',
        maxWidth:'66.66%',
        '@media (max-width:960px)': {
            position:'static',
            bottom:'auto',
            left:'auto',
            order:'2',
            margin:'0 auto',
            maxWidth:'100%',
        }
    },
    buildingImg : {
        width:'100%',
        position: 'absolute',
        bottom: 0,

        '@media (max-width:960px)': {
            position: 'static',
        }
    },
    sectionGallery : {
        width:'100%',
        height:'auto',
        paddingRight:'2%',
        '@media (max-width:960px)': {
            paddingRight:'0',
        }
    },
    sectionGalleryWrapper : {
        marginBottom:'2%'
    },
    textWrapper : {
        position:'absolute',
        top:'40%',
        transform: 'translate(0%, -50%)',
        maxWidth:'28vw',
        width:'90%',
        '@media (max-width:960px)': {
            position: 'static',
            top:'auto',
            transform:'none',
        }
    },
    section1_textWrapper: {
        top: '40%',
        width: '28vw',
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        minHeight: '222px',

        '@media (max-width:960px)': {
            position:'static',
            top:'auto',
            right:'auto',
            width:'100%',
            textAlign:'center',
            marginTop:"70px",
            marginBottom:"0px",
            minHeight: 'auto',
            transform: 'none',
        }
    },
    withTableStriped: {
        marginTop:'5%',
        marginBottom:'5%',
        '& h3': {
            color: '#99784c !important',
        },

        '& .table': {
            width: '100%',
            margin: 0,

            '& table': {
                width: '100%',
                margin: 0,
                padding: 0,
                border: 0,
                borderSpacing: 0,
                textAlign: 'left',
                borderBottom: '1px solid #817256',
                marginBottom: 30,

                '& thead': {
                    background: '#817256',
                    color: '#ffffff',
                    border: 0,

                    '& tr': {
                        '& th': {
                            padding: 10,
                        },
                    },
                },

                '& tbody': {
                    '& tr': {
                        '& td': {
                            padding: 7,
                            fontSize: 13,
                            fontFamily:'SabonNextLT'
                        },
                    },

                    '& tr:nth-child(even)': {
                        background: '#e8e4db',
                    },
                },
            },
        },
    },
    sectionAboutUs : {

        '& h1 span': {
            color: '#99784c !important',
        },

        '& .table': {
            margin: 0,

            '& td': {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',

                '& .image': {
                    width: '49%',
                    margin: 0,

                    '& img': {
                        width: '100%',
                    },

                    '& figcaption': {
                        color: '#99784c',
                        fontSize: 19,
                        marginBottom: 30,
                        marginTop: 10,
                        fontFamily:'HelveticaNeue-thin!important'
                    },
                },
                '@media (max-width:800px)': {
                    '& .image': {
                        width: '100%',
                        margin: 0,
                    },
                }
            },
        }
    },
    centerWrapper : {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        paddingTop:'5%',
        paddingBottom:'5%',

        '& span': {
            background: 'none!important' as any,
        },
        '& h4 span,& h4' : {
            color: '#99784c !important',
            fontWeight:'900'
        },

        '& figure.image:nth-child(1)': {
            width:'60%',

            '@media (max-width:960px)': {
                width:'100%'
            },
            '& img': {
                width:'100%'
            }
        },

        '& figure.image:nth-child(4)': {
            '@media (max-width:400px)': {
                width:'100%',
                '& img': {
                    width:'100%'
                }
            },
        },


        '@media (max-width:960px)': {
            position: 'static',
            top:'auto',
            left:'auto',
            transform:'none',
            margin:'30px 0'
        }
    },
    AboutUsContainer : {
        marginTop:'3%',
        marginBottom:'3%',
    },
    awardsImg : {
        padding: '10% 0',
        width: '100%',
        height: 'auto'
    },
    section1_textContainer : {

        '@media (max-width:1400px)': {
            right:'-8%',
        },
        '@media (max-width:960px)': {
            margin:'0 auto',
            position:'relative',
            top:'auto',
            transform:'none',
            width  :'auto',
            right:'auto',
            padding: '5%',
        }
    }
}))


const AboutUs = () => {
    const classes = useStyles();
    const mobileScreen = useMediaQuery('(max-width:960px)');

    return (
        <Wrapper>
            <GlobalNav activeItem="aboutus"/>
            <HeaderBar />

            <Query query={QUERY}>
                {({ data: { aboutUs }, loading }) => {

                if (loading || !aboutUs) {
                    return  <LoadingSpinner />;
                }

                return(
                    <>
                        <Grid container>
                            <Grid container  className={classes.buildingContainer}>
                                {/* <StaticCoin x={7} y={9.5} mobile={false} />
                                <StaticAlphabet text={'N'} x={35}  y={8} mobile={false} />
                                <StaticCoin x={55} y={10} mobile={false} />
                                <StaticAlphabet text={'R'} x={95}  y={8} mobile={false} />
                                <StaticAlphabet text={'E'} x={75}  y={8} mobile={false} />
                                <StaticAlphabet text={'E'} x={22}  y={25} mobile={false} />

                                <StaticAlphabet text={'F'} x={65}  y={20} mobile={false} />
                                <StaticCoin x={85} y={22} mobile={false} />

                                <StaticAlphabet text={'P'} x={85}  y={50} mobile={false} />
                                <StaticCoin x={65}  y={50} mobile={false} />

                                <StaticAlphabet text={'C'} x={5}  y={70} mobile={false} />
                                <StaticAlphabet text={'N'} x={55}  y={70} mobile={false} />
                                <StaticAlphabet text={'C'} x={70}  y={70} mobile={false} />
                                <StaticCoin x={95} y={73} mobile={false} />
                                <StaticAlphabet text={'N'} x={25}  y={70} mobile={false} />
                                <StaticAlphabet text={'R'} x={60}  y={90} mobile={false} />
                                <StaticAlphabet text={'E'} x={85}  y={90} mobile={false} />

                                <StaticAlphabet text={'N'}  x={50} y={2} mobile={true}/>
                                <StaticAlphabet text={'R'}  x={3} y={15} mobile={true}/>
                                <StaticAlphabet text={'R'}  x={91} y={20} mobile={true}/>
                                <StaticCoin x={7} y={70} mobile={true} />
                                <StaticAlphabet text={'F'}  x={10} y={26} mobile={true}/>
                                <StaticAlphabet text={'N'}  x={50} y={32} mobile={true}/>
                                <StaticAlphabet text={'E'}  x={89} y={37} mobile={true}/> */}

                                <Grid item md={8} className={classes.buildingImgContainer}>
                                {mobileScreen === true ?
                                <img src={building_mobile} className={classes.buildingImg} alt={aboutUs.HeaderTitle} />
                                :
                                <img src={`${process.env.REACT_APP_BACKEND_URL}${aboutUs.HeaderImage.url}`} className={classes.buildingImg} alt={aboutUs.HeaderTitle} /> }
                                </Grid>
                                <Grid item md={4} className={classes.section1_textContainer}>
                                    <div className={classes.section1_textWrapper}>
                                        {parse(aboutUs.HeaderTitle)}
                                        <p>{aboutUs.HeaderDescription}</p>
                                    </div>
                                </Grid>
                            </Grid>


                            <Container maxWidth="md" className={classes.AboutUsContainer}>
                                <div className={`${classes.sectionAboutUs} sectionForH1`}>
                                    {parse(aboutUs.CKAssetsHoldingLimited)}
                                </div>
                            </Container>


                            <Grid container className={classes.sectionBg}>
                                <Grid item md={12}>
                                    <Container maxWidth="md" className={`${classes.centerWrapper} sectionForH1`}>
                                        {parse(aboutUs.ArchitectDesignStatement)}
                                    </Container>
                                </Grid>
                            </Grid>

                            <Container maxWidth="md" >
                                <Grid item md={12} className={`${classes.withTableStriped} sectionForH1`}>
                                    {parse(aboutUs.AwardsRecognition)}
                                </Grid>
                            </Container>

                            <Grid container className={classes.sectionBg}>
                                <Grid item md={12}>
                                    <Container maxWidth="md" className={`${classes.centerWrapper} sectionForH1`}>
                                        {parse(aboutUs.LandscapeDesignStatement)}
                                    </Container>
                                </Grid>
                            </Grid>

                            <Container maxWidth="md" >
                                <Grid item md={12} className={`${classes.withTableStriped} sectionForH1`} >
                                    {parse(aboutUs.AwardsRecognitionSaladDressing)}
                                </Grid>
                            </Container>
                        </Grid>
                    </>)
                }}
            </Query>
            <Footer />
        </Wrapper>
    )
}

export default AboutUs;