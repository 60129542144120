import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '../components/Button';
import H3 from '../components/h3'
import parse from 'html-react-parser';
import { useDispatch,useSelector } from 'react-redux';
import { cardActions } from '../actions/cardActions';

const useStyles = makeStyles({
    root: {
        maxWidth: 340,
        margin:'10px 20px',
        minWidth:'33%',
        cursor:'pointer',
        position:'relative',
        "&::before": {
            top: '50%',
            right:' 50%',
            width: '0%',
            zIndex: 1,
            height: '90%',
            content: "''",
            position: 'absolute',
            transition: 'all 0.3s ease-in-out',
            backgroundColor: 'rgba(255,255,255,0.8)',
            transform: 'translate(50%, -50%)'
        },
        "&.active" : {
            "&::before" : {
                width: '90%'
            },
            "& p": {
                opacity:1,
                transform:'translateY(0px)'
            },
            "& span": {
                opacity:1,
                transform:'translateY(0px)'
            }
        },
    },
    header : {
        margin:'10px 20px',
        '@media (max-width:960px)': { 
            textAlign:'center',
        }
    },
    cardImg : {
        width: '100%',
    },
    active: {
        pointerEvents:'auto'
    },
    inactive: {
        pointerEvents:'none'
    },
    hoverBackground : {
        position:'absolute',
        alignItems: 'center',
        top: '50%',
        right: '0px',
        left: '0px',
        zIndex: 2,
        padding: '0px 30px',
        textAlign: 'center',
        transform: 'translateY(-50%)',
        
    },
    hoverWrapper : {
        "& p": {
            opacity:0,
            transitionDelay: '0.1s',
            transition: 'all 0.1s ease-in-out',
            display: 'block',
            margin: '0 auto',
            width: '230px',
            paddingBottom:'20px',
        },
        "& span" :{
            opacity:0,
            transitionDelay: '0.1s',
            transition: 'all 0.1s ease-in-out',
            width : '100%',
            display: 'block',
    },
}
});

const LocationCard = (props:any) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const currentCard = useSelector((state:any) => state.card.currentCard)

    const cardActive = (props) => {
        console.log(props)
        dispatch(cardActions.activeCard(props.id))
    }

    return(
        <>
        <div className={`${classes.root} ${(props.id === currentCard) ? 'active': ''}`} onClick={() => cardActive(props)} onMouseEnter={() => cardActive(props)}>
            <img src={props.img} className={classes.cardImg} alt="card"/>
            <div className={classes.hoverBackground}>
                <div className={classes.hoverWrapper}>
                    <p>{parse(props.text)}</p>
                    <span className={`${(props.id === currentCard) ? classes.active: classes.inactive}`}>
                        <Button text={'MORE DETAILS'} type={'locationmap'} source={props.source}/>
                    </span>
                </div>
            </div>
        </div>
        <div className={classes.header} onClick={() => cardActive(props)}>
            <H3>{props.header}</H3>
        </div>
        </>
    );
}

export default LocationCard;