import React, { useState } from "react";
import Wrapper from "components/Wrapper";
import gql from "graphql-tag";
import GlobalNav from "components/GlobalNav";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Footer from "../components/Footer";
import H1 from "../components/h1";
import Button from "../components/Button";
import Container from "@material-ui/core/Container";
import HeaderBar from "../components/HeaderBar";
import { Fade } from "react-awesome-reveal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwiperImage from "../components/SwiperImage";
import { Query } from "react-apollo";
import parse from "html-react-parser";
import LoadingSpinner from "../components/LoadingSpinner";

// image
import bg from "../assets/images/overview/bg.png";
import section1_bg from "../assets/images/amenities/section1_bg.png";
import section1_img_mobile from "../assets/images/endorsement/endbanner_mobile.jpg";

import { useDispatch } from "react-redux";
import { modalActions } from "../actions/modalActions";

const QUERY = gql`
query {
  endorsement {
    Name
    HeaderImage {
      url
    }
    HeaderTitle
    HeaderDescription
    VideoGallery {
      Name
      Description
      Link
      Thumbnail {
        url
      }
      Video {
        url
      }
    }
  }
}
`;

const useStyles = makeStyles(() => ({
  section1_bg: {
    width: "100%",
    position: "relative",
    objectFit: "cover",
    "@media (max-width:960px)": {
      position: "static",
      order: 2,
    },
  },
  section1_bg_video: {
    width: "100%",
    position: "relative",
    objectFit: "cover",
    "@media (max-width:960px)": {
      position: "static",
      order: 2,
    },
  },
  section_header: {
    margin: "6% 0 2%",
    textAlign: "center",
  },
  sectionContainer: {
    position: "relative",
    background: `url(${section1_bg}) left top / cover`,
    minHeight: "auto",
    "@media (max-width:960px)": {
      minHeight: "auto",
    },
  },
  swiperContainer: {
    "@media (max-width:960px)": {
      display: "block",
      paddingTop: "40px",
      paddingBottom: "80px",
    },
  },
  sectionBg: {
    background: `url(${bg}) left top / cover`,
  },
  buildingContainer: {
    background: `url(${bg}) left top / cover`,
    minHeight: "100vh",
    position: "relative",
  },
  textWrapper: {
    position: "absolute",
    top: "50%",
    transform: "translate(0%, -50%)",
    maxWidth: "28vw",
    width: "90%",
    "@media (max-width:960px)": {
      position: "static",
      top: "auto",
      transform: "none",
      width: "100%",
      maxWidth: "100%",
    },
  },
  section1_textWrapper: {
    position: "absolute",
    top: "40%",
    transform: "translate(-50%, -50%)",
    width: "28vw",
    maxWidth: "100%",
    minHeight: "222px",
    left: "25%",

    "@media (max-width:960px)": {
      position: "static",
      top: "auto",
      transform: "none",
      left: "auto",
      textAlign: "center",
      marginTop: "70px",
      width: "100%",

      "& h1": {
        width: "80%",
        margin: "0 auto",
      },
    },
  },
  textContainer: {
    position: "relative",
  },
  textWrapperRight: {
    position: "absolute",
    top: "50%",
    transform: "translate(0%, -50%)",
    width: "380px",
    right: 0,
    "@media (max-width:960px)": {
      position: "static",
      top: "0",
      transform: "none",
      width: "100%",
      right: "0",
    },
  },
  contentImage: {
    "@media (max-width:960px)": {
      paddingTop: "40px",
      paddingBottom: "40px",
    },
  },
  section_center: {
    textAlign: "center",
  },
  galleryGrid: {
    paddingRight: "20px",
    width: "100%",
    "& img": {
      width: "100%",
      paddingBottom: "15px",
      cursor: "pointer",
    },
    "@media (max-width:960px)": {
      paddingRight: "0px",
    },
  },
  galleryGrid_paddingLeft: {
    paddingLeft: "10px",
    "@media (max-width:960px)": {
      paddingLeft: "0px",
      width: "100%",
    },
  },
  galleryGrid_paddingRight: {
    paddingRight: "10px",
    "@media (max-width:960px)": {
      paddingRight: "0px",
      width: "100%",
    },
  },
  galleryGrid_nopadding: {
    paddingRight: "0px",
    "& img": {
      width: "100%",
      paddingBottom: "0px",
      cursor: "pointer",
    },
    "@media (max-width:960px)": {
      paddingRight: "0px",
    },
  },
  galleryGrid_nopaddingBottom: {
    paddingRight: "20px",
    "& img": {
      width: "100%",
      paddingBottom: "0px",
      cursor: "pointer",
    },
    "@media (max-width:960px)": {
      paddingRight: "0px",
    },
  },
  contentContainer: {
    "@media (max-width:960px)": {
      overflow: "hidden",
      maxWidth: "100%",
    },
  },
  section1_bgWrapper: {
    width: "100%",
    "@media (max-width:960px)": {
      order: 2,
    },
  },
  swiperContainerReverse: {
    "@media (max-width:960px)": {
      display: "block",
      paddingBottom: "40px",
      paddingTop: "80px",
      transform: " scaleY(-1)",
    },
  },
}));

const Endorsement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileScreen = useMediaQuery("(max-width:960px)");

  return (
    <Wrapper>
      <GlobalNav activeItem='endorsement' />
      <HeaderBar />

      <Query query={QUERY}>
        {({ data: { endorsement }, loading }) => {
          if (loading || !endorsement) {
            return <LoadingSpinner />;
          }

          return (
            <>
              <Grid container className={`${classes.sectionContainer}`}>
                <Grid item className={classes.section1_bgWrapper}>
                  {mobileScreen === true ? (
                    (endorsement.HeaderImage.url.substr(endorsement.HeaderImage.url.length - 3) == 'jpg' || endorsement.HeaderImage.url.substr(endorsement.HeaderImage.url.length - 3) == 'png') ? <img
                    src={section1_img_mobile}
                    className={classes.section1_bg}
                    alt='bg_img'
                  /> : <video
                    autoPlay
                    controls
                    className={classes.section1_bg_video}
                    src={`${process.env.REACT_APP_BACKEND_URL}${
                      endorsement.HeaderImage.url
                    }`}
                    controlsList="nodownload nopictureinpicture noremoteplayback"
                  />
                  ) : (
                  (endorsement.HeaderImage.url.substr(endorsement.HeaderImage.url.length - 3) == 'jpg' || endorsement.HeaderImage.url.substr(endorsement.HeaderImage.url.length - 3) == 'png') ? <img
                  src={`${process.env.REACT_APP_BACKEND_URL}${
                    endorsement.HeaderImage.url
                  }`}
                  className={classes.section1_bg}
                  alt='bg_img'
                  />
                  :
                  <video
                    autoPlay
                    controls
                    className={classes.section1_bg_video}
                    src={`${process.env.REACT_APP_BACKEND_URL}${
                      endorsement.HeaderImage.url
                    }`}
                    controlsList="nodownload nopictureinpicture noremoteplayback"
                  />
                  )}
                </Grid>
                {/* <Grid item md={4}>
                  <div
                    className={`${
                      classes.section1_textWrapper
                    }  contentWrapper`}
                  >
                    {parse(endorsement.HeaderTitle)}
                    {parse(endorsement.HeaderDescription)}
                  </div>
                </Grid> */}
              </Grid>

              {endorsement.VideoGallery.map((section, i) => {
                return i % 2 === 0 ? (
                  <Grid
                    container
                    key={section.Name}
                    className={`${classes.swiperContainer} contentWrapper`}
                  >
                    <Grid item md={8}>
                      <div className={`sectionImg_leftNomargin`}>
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}${
                            section.Thumbnail.url
                          }`}
                          className={`img`}
                          alt='architecture'
                        />
                      </div>
                    </Grid>
                    <Grid item md={4} className={`${classes.textContainer}`}>
                      <div className={classes.textWrapper}>
                        <H1>&ldquo;{section.Name}&rdquo;</H1>
                        {parse(section.Description)}
                        <Button
                          text={"Read More"}
                          type={"endorsement"}
                          source={{title: section.Name, description: section.Description, link: section.Link, video: process.env.REACT_APP_BACKEND_URL + section.Video?.url}}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    key={section.Name}
                    className={classes.sectionBg}
                  >
                    <Grid
                      container
                      className={`contentWrapper ${classes.contentImage}`}
                    >
                      <Grid
                        item
                        md={4}
                        className={`${classes.textContainer} reverse_991`}
                      >
                        <div className={classes.textWrapperRight}>
                          <H1>&ldquo;{section.Name}&rdquo;</H1>
                          {parse(section.Description)}
                          <Button
                            text={"Read More"}
                            type={"endorsement"}
                            source={{title: section.Name, description: section.Description, link: section.Link, video: process.env.REACT_APP_BACKEND_URL + section.Video.url}}
                          />
                        </div>
                      </Grid>
                      <Grid item md={8}>
                        <div className={`sectionImg_right`}>
                          <img
                            src={`${process.env.REACT_APP_BACKEND_URL}${
                              section.Thumbnail.url
                            }`}
                            className={`img`}
                            alt='architecture'
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          );
        }}
      </Query>
      <Footer />
    </Wrapper>
  );
};

export default Endorsement;
