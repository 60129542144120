import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
// import StaticCoin from '../components/StaticCoin'
import RotateCoin from '../components/RotateCoin'
import ReverseRotateCoin from '../components/ReverseRotateCoin'
import StaticAlphabet from '../components/StaticAlphabet'
// image
import bg from '../assets/images/landing_bg.png'
import { Logo } from 'components/Global/global'

const useStyles = makeStyles(() => ({
    root : {
        overflow:'hidden',
        cursor:'pointer',
        height:'100vh'
    },
    section1_bg : {
        minHeight:'100vh',
        minWidth:'100vw'
    },
    section1_textWrapper: {
        position:'absolute',
        top:'48%',
        transform: 'translate(-50%, -50%)',
        width:'65%',
        maxWidth:'320px',
        left:'50%',
        "& img" : {
            width:'100%'
        }
    },
}))

const Landing = () => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const path     =  useSelector((state:any) => state.router.location.pathname);
    let chgPath;
    React.useEffect(() => {
        chgPath = setTimeout(function(){
            if(path === '/') {
                dispatch(push('/overview'))
            }
        },8000);
        return () => { clearTimeout(chgPath); }
    },[path]);

    return(
        <Grid container className={classes.root} onClick={() => dispatch(push('/overview'))}>
            <Grid item md={12}>
                {/* desktop */}
                {/* First row */}
                <StaticAlphabet text={'N'} x={20}  y={6} mobile={false}/>
                <StaticAlphabet text={'E'} x={40}  y={6}  mobile={false}/>
                <StaticAlphabet text={'F'} x={55}  y={6}  mobile={false}/>
                <StaticAlphabet text={'R'} x={70}  y={6}  mobile={false}/>
                <StaticAlphabet text={'N'} x={85}  y={6}  mobile={false}/>
                {/* second row */}
                <ReverseRotateCoin speed={5}  x={10}  y={20}  mobile={false}/>
                <StaticAlphabet text={'C'} x={30}  y={20}  mobile={false}/>
                <StaticAlphabet text={'N'} x={47}  y={20}  mobile={false}/>
                <ReverseRotateCoin speed={10}  x={60}  y={20}  mobile={false}/>
                <StaticAlphabet text={'E'} x={78}  y={20}  mobile={false}/>
                <StaticAlphabet text={'R'} x={95}  y={20}  mobile={false}/>
                {/* third row */}
                <StaticAlphabet text={'E'} x={20}  y={34}  mobile={false}/>
                <RotateCoin speed={12} x={40}  y={36}  mobile={false}/>
                <StaticAlphabet text={'R'} x={55}  y={34}  mobile={false}/>
                <StaticAlphabet text={'F'} x={70}  y={34}  mobile={false}/>
                <ReverseRotateCoin speed={14}  x={85}  y={37}  mobile={false}/>
                {/* fourth row */}
                <StaticAlphabet text={'N'} x={10}  y={48}  mobile={false}/>
                <StaticAlphabet text={'R'} x={30}  y={48}  mobile={false}/>
                <StaticAlphabet text={'N'} x={78}  y={48}  mobile={false}/>
                <StaticAlphabet text={'E'} x={95}  y={48}  mobile={false}/>
                {/* fifth row */}
                <RotateCoin speed={4} x={20}  y={64}  mobile={false}/>
                <StaticAlphabet text={'P'} x={40}  y={63}  mobile={false}/>
                <StaticAlphabet text={'E'} x={55}  y={63}  mobile={false}/>
                <RotateCoin speed={8} x={70}  y={63}  mobile={false}/>
                <StaticAlphabet text={'P'} x={85}  y={63}  mobile={false}/>
                {/* six row */}
                <StaticAlphabet text={'C'} x={10}  y={80}  mobile={false}/>
                <StaticAlphabet text={'N'} x={30}  y={80}  mobile={false}/>
                <StaticAlphabet text={'R'} x={47}  y={80}  mobile={false}/>
                <StaticAlphabet text={'N'} x={65}  y={80}  mobile={false}/>
                <StaticAlphabet text={'C'} x={78}  y={80}  mobile={false}/>
                <ReverseRotateCoin x={95}  y={83}  mobile={false}/>
                {/* seventh row */}
                <StaticAlphabet text={'F'} x={20}  y={92}  mobile={false}/>
                <StaticAlphabet text={'C'} x={40}  y={92}  mobile={false}/>
                <RotateCoin speed={20} x={55}  y={94}  mobile={false}/>
                <StaticAlphabet text={'R'} x={70}  y={92}  mobile={false}/>
                <StaticAlphabet text={'E'} x={85}  y={92}  mobile={false}/>
                <RotateCoin x={95}  y={83}  mobile={false}/>

                {/* mobile */}
                <StaticAlphabet text={'C'} x={15}  y={2}  mobile={true}/>
                <StaticAlphabet text={'N'} x={68}  y={2}  mobile={true}/>
                <StaticAlphabet text={'N'} x={42}  y={15}  mobile={true}/>
                <StaticAlphabet text={'E'} x={90}  y={15}  mobile={true}/>
                <RotateCoin speed={15} x={16}  y={30}  mobile={true}/>
                <StaticAlphabet text={'C'} x={68}  y={29}  mobile={true}/>
                <StaticAlphabet text={'E'} x={90}  y={15}  mobile={true}/>
                <StaticAlphabet text={'N'} x={15}  y={60}  mobile={true}/>
                <StaticAlphabet text={'R'} x={68}  y={60}  mobile={true}/>
                <RotateCoin speed={12} x={42}  y={74}  mobile={true}/>
                <StaticAlphabet text={'P'} x={90}  y={73}  mobile={true}/>
                <StaticAlphabet text={'C'} x={15}  y={87}  mobile={true}/>
                <StaticAlphabet text={'N'} x={67}  y={87}  mobile={true}/>
                <StaticAlphabet text={'F'} x={42}  y={95}  mobile={true}/>
                <StaticAlphabet text={'C'} x={90}  y={95}  mobile={true}/>

                <img src={bg} className={classes.section1_bg} alt="background"/>
                <Grid item md={4}>
                    <div className={classes.section1_textWrapper}>
                        <Logo />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Landing