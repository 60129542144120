import * as React from "react"

function C(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.49 20.87"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path" transform="translate(3 2.42)">
          <path
            className="prefix__cls-1"
            d="M5 .61a8 8 0 00-2.6 1.7A7.69 7.69 0 00.63 4.86 7.88 7.88 0 000 8a7.89 7.89 0 00.63 3.16 7.69 7.69 0 001.77 2.56 8 8 0 002.65 1.7 8.87 8.87 0 003.29.58 9 9 0 002.79-.43 8.09 8.09 0 002.18-1.09l.19-.13-.8-1.35-.23.18a6.13 6.13 0 01-1.77.89 7.4 7.4 0 01-2.28.35 7.12 7.12 0 01-2.66-.49 6.5 6.5 0 01-2.1-1.36 6.27 6.27 0 01-1.37-2A6.61 6.61 0 011.81 8a6.51 6.51 0 01.48-2.5 6.21 6.21 0 011.37-2 6.5 6.5 0 012.1-1.36 7.12 7.12 0 012.66-.49A7.4 7.4 0 0110.7 2a6.13 6.13 0 011.77.89l.23.11.8-1.38-.19-.13A7.87 7.87 0 0011.12.43 8.75 8.75 0 008.34 0 8.73 8.73 0 005 .61"
          />
        </clipPath>
        <style>{".prefix__cls-1{fill:#99784c}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M5 .61a8 8 0 00-2.6 1.7A7.69 7.69 0 00.63 4.86 7.88 7.88 0 000 8a7.89 7.89 0 00.63 3.16 7.69 7.69 0 001.77 2.56 8 8 0 002.65 1.7 8.87 8.87 0 003.29.58 9 9 0 002.79-.43 8.09 8.09 0 002.18-1.09l.19-.13-.8-1.35-.23.18a6.13 6.13 0 01-1.77.89 7.4 7.4 0 01-2.28.35 7.12 7.12 0 01-2.66-.49 6.5 6.5 0 01-2.1-1.36 6.27 6.27 0 01-1.37-2A6.61 6.61 0 011.81 8a6.51 6.51 0 01.48-2.5 6.21 6.21 0 011.37-2 6.5 6.5 0 012.1-1.36 7.12 7.12 0 012.66-.49A7.4 7.4 0 0110.7 2a6.13 6.13 0 011.77.89l.23.11.8-1.38-.19-.13A7.87 7.87 0 0011.12.43 8.75 8.75 0 008.34 0 8.73 8.73 0 005 .61"
            transform="translate(3 2.42)"
          />
        </g>
      </g>
    </svg>
  )
}

export default C
