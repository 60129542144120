import styled from 'styled-components'

const HeaderBar = styled.div`
    height:75px;
    width:100%;
    background-color:#fcfdf5;
    @media (max-width: 960px) {
        height:70px;
    }
`

export default HeaderBar