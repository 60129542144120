import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router'
import Overview from 'routes/Overview'
import NotFound from 'routes/NotFound'
import { History } from 'history'
import { RootProps } from 'types/common'
import Amenities from 'routes/Amenities'
import Architecture from 'routes/Architecture'
import Enquiries from 'routes/Enquiries'
import Floorplan from 'routes/Floorplan'
import Endorsement from 'routes/Endorsement'
import Location from 'routes/Location'
import AboutUs from 'routes/AboutUs'
import Landing from 'routes/Landing'
import ThankYou from 'routes/ThankYou'

const App: React.FC<RootProps> = ({ history }: { history: History }) => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route exact path="/landing" component={Landing} />
                <Route exact path="/" component={Landing} />
                <Route exact path="/overview" component={Overview} />
                <Route exact path="/amenities" component={Amenities} />
                <Route exact path="/architecture" component={Architecture} />
                <Route exact path="/aboutus" component={AboutUs} />
                <Route exact path="/enquiries" component={Enquiries} />
                <Route exact path="/floorplan" component={Floorplan} />
                <Route exact path="/endorsement" component={Endorsement} />
                <Route exact path="/location" component={Location} />
                <Route exact path="/thankyou" component={ThankYou} />
                <Route component={NotFound}/>
            </Switch>
        </ConnectedRouter>
    )
}

export default App