import user from './user'
import github from './github'
import {modal} from './modal'
import {card} from './card'

export default {
    ...user,
    ...github,
    modal,
    card
}