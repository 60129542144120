import React from 'react'
import Wrapper from 'components/Wrapper'
import GlobalNav from 'components/GlobalNav';
import HeaderBar from '../components/HeaderBar';
import Grid from '@material-ui/core/Grid';
import H1 from '../components/h1';
import { makeStyles } from '@material-ui/core/styles';
import bg from '../assets/images/enquiries/bg.png';
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

const useStyles = makeStyles(() => ({
    bgWrapper:{
        background: `url(${bg}) left top / cover`,
        minHeight:'100vh',
        position:'relative',
        width:'100vw'
    },
    textWrapper: {
        position:'absolute',
        left:'50%',
        top:'50%',
        textAlign:'center',
        transform:'translate(-50%,-50%)'
    }
}));

const ThankYou = () => {
    const classes   = useStyles();
    const dispatch  = useDispatch();

    React.useEffect(() => {
        setTimeout(function(){
            dispatch(push('/overview'))
        },3000);
    },[]);

    return(
        <Wrapper className={classes.bgWrapper}>
            <GlobalNav activeItem="thankyou"/>
            <HeaderBar />
            <Grid item md={12} >
                <div className={classes.textWrapper}>
                    <H1>Thank You</H1>
                    <p>Thank you for your enquiry, our representative will be in touch shortly.</p>
                </div>
            </Grid>
        </Wrapper>
    )
}

export default ThankYou;

  