import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
    bgWrapper:{
        minHeight:'100vh',
        position:'relative',
        width:'100%',
    },
    spinner : {
        width:'50px!important',
        height:'50px!important',
        margin: '0 auto',
        position: 'absolute',
        top:'40%',
        left:'50%',
    }
}));

const LoadingSpinner = () => {
 
    const classes   = useStyles();

    return (
        <div className={classes.bgWrapper}>
             <CircularProgress className={classes.spinner} color="primary" />
        </div>
    )
}

export default LoadingSpinner;