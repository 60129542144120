import * as React from "react"

function E(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.85 18.74"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path" transform="translate(2.89 1.48)">
          <path
            className="prefix__cls-1"
            d="M0 0v15.79h8.07v-1.51H1.68V8.63h6.16V7.12H1.68V1.51h6.39V0H0z"
          />
        </clipPath>
        <style>{".prefix__cls-1{fill:#99784c}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M2.89 1.48v15.78h8.07v-1.5H4.57V10.1h6.16V8.6H4.57V2.98h6.39v-1.5H2.89z"
          />
        </g>
      </g>
    </svg>
  )
}

export default E
