import {cardConstants} from '../constants';

const initialState = { currentCard : null}

export function card(state = initialState,action:any) {
    switch(action.type) {
        case cardConstants.ACTIVE_CARD_REQUEST:
            return {
                ...state.currentCard,
                currentCard: action.cardId
            }
        default:
            return state;
    }
}