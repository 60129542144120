import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../actions/modalActions";
import Iframe from "react-iframe";
import ButtonStandard from "../components/Button";
import bg from "../assets/images/floorplan/bg.png";
import btnClose from "../assets/images/btn-close.png";
import SwiperImage from "../components/SwiperImage.js";
import SwiperGallery from "../components/SwiperGallery.js";
import parse from "html-react-parser";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnDownload: {
      position: "absolute",
      top: "28px",
      right: "102px",
    },
    modal: {
      width: "100%",
      height: "auto",
      backgroundColor: "rgba(255,255,255,0.9)",
    },
    iframeContainer: {
      width: "100vw",
      height: "100vh",
      border: 0,
    },
    iframeStyle: {
      border: 0,
    },
    backgroundContainer: {
      background: `url(${bg}) left top / cover`,
      paddingBottom: "4%",
      position: "relative",
    },
    container: {
      paddingBottom: "4%",
      position: "relative",
    },
    paper: {
      backgroundColor: "#fff",
      width: "100%",
      textAlign: "center",
    },
    wrapper: {
      marginTop: "5%",
      marginBottom: "5%",
      textAlign: "left",
      "@media (max-width:960px)": {
        textAlign: "center",
      },
    },
    floorplan: {
      background: `url(${bg}) left top / cover`,
      height: "100vh",
      textAlign: "left",
      padding: "10vh 10vw",
      overflowY: "auto",
      overflowX: "hidden",

      "& img": {
        width: "100%",
      },

      "& h3": {
        fontSize: 24,
        color: "#99784c",
      },

      "& ol": {
        columns: 1,
        listStyle: "decimal",
        textAlign: "left",
        margin: 0,
        padding: 0,
        listStyleType: "none",

        "& li": {
          counterIncrement: "stepcounter",
          marginLeft: 0,
          padding: "0px 8px",
          // marginBottom: 4,
          paddingLeft: 0,

          "&::before": {
            content: `counter(stepcounter)`,
            color: "white",
            fontSize: "10px",
            fontWeight: "bold",
            marginRight: "5px",
            borderRadius: "10px",
            backgroundColor: "#A97B50",
            width: "18px",
            display: "inline-block",
            textAlign: "center",
          },
        },
      },

      "& .masterroof": {
        display: "flex",
        paddingBottom: 50,

        "@media (max-width:960px)": {
          flexDirection: "column-reverse",
        },

        "& > div": {
          width: "50%",

          "@media (max-width:960px)": {
            width: "100%",
          },
        },

        "& ol": {
          float: "left",
          width: "50%",
          "@media (max-width:960px)": {
            width: "100%",
          },
        },

        "& h4": {
          marginBlockEnd: "0em",
        },
      },

      "& .roofplan": {
        display: "flex",
        flexDirection: "column",
        paddingBottom: 50,

        "& > div": {
          width: "100%",
        },

        "& > div:nth-child(2)": {
          "@media (max-width:960px)": {
            marginTop: "30px",
          },
        },
      },

      "& .special": {
        color: "#6d6e71",
        "& span": {
          backgroundColor: "#6d6e71",
        },
      },

      "& p": {
        marginLeft: 0,
        padding: "0px 8px",
        marginBottom: 4,
        paddingLeft: 0,
        color: "black",
        marginBlockStart: "0em",
        marginBlockEnd: "0em",
        "& > span": {
          color: "white",
          fontSize: "10px",
          fontWeight: "bold",
          marginRight: "5px",
          borderRadius: "10px",
          backgroundColor: "#A97B50",
          width: "18px",
          height: "18px",
          display: "inline-block",
          textAlign: "center",
        },
      },
    },
    siteplanWrapper: {
      width: "50%",
      float: "right",
    },
    img: {
      width: "100%",
    },
    imgWrapper: {
      width: "80%",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)",
      "@media (max-width:960px)": {
        width: "100%",
      },
    },
    endorseWrapper: {
      width: "40%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)",
      textAlign: "left",
      "& h1": {
        fontSize: "1.9rem !important",
        width: "100%",
      },
      "& a": {
        textTransform: "uppercase",
        padding: "4px 32px",
        border: "1px solid #99774c",
        margin: "0 auto",
        marginTop: "20px",
        fontSize: "12px",
        color: "#99774c",
        fontFamily: "HelveticaNeue-light!important",
        position: "absolute",
        bottom: "-60px",
        right: 0,
      },
      "@media (max-width:960px)": {
        width: "100%",
        padding: "0px 16px",

        "& a": {
          bottom: "-60px",
          right: "16px",
        },
      },
    },
    img_swipe: {
      width: "70%",
      position: "absolute",
      left: "50%",
      top: "46%",
      transform: "translate(-50%,-50%)",
      "@media (max-width:600px)": {
        width: "90%",
        top: "45%",
      },
    },
    btnClose: {
      width: "20px",
      position: "fixed",
      right: "50px",
      top: "50px",
      cursor: "pointer",
      zIndex: 2,
      "@media (max-width:960px)": {
        top: "4%",
        right: "4%",
      },
    },
    btnClose_Ebooklet: {
      width: "20px",
      position: "fixed",
      right: "50px",
      top: "65px",
      cursor: "pointer",
      zIndex: 2,
      "@media (max-width:960px)": {
        right: "4%",
      },
    },
    spWrapper: {
      padding: "2% 2% 2% 0%",
      textAlign: "left",
      "@media (max-width:960px)": {
        width: "50%",
        float: "left",
      },
    },
    spWrapper_full: {
      padding: "2% 2% 2% 0%",
      textAlign: "left",
      "@media (max-width:960px)": {
        width: "100%",
        float: "left",
      },
    },
    provisionFooter: {
      textAlign: "right",
      padding: "2% 0%",
      position: "absolute",
      bottom: "-15px",
      right: "100px",
      "@media (max-width:960px)": {
        bottom: "0px",
        right: "50%",
        transform: "translate(50%, 0%)",
      },
    },
    brand_logo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "70%",
      },
      "@media (max-width:960px)": {
        width: "50%",
        padding: "2%",
        marginRight: "0",
      },
    },
    bedroomWrapper: {
      "& figcaption": {
        fontFamily: "HelveticaNeue-thin!important",
      },
      "& > figure:nth-of-type(1)": {
        "& > table": {
          "& > tbody": {
            "& > tr": {
              "& > td": {
                "@media (max-width:1300px)": {
                  width: "80%",
                  margin: "0 auto",
                  display: "flex",
                  flexDirection: "column",
                },
              },
            },
          },
        },
      },

      "& > figure:nth-of-type(1), & > figure:nth-of-type(2)": {
        "& > table": {
          marginLeft: "auto!important",
          marginRight: "auto!important",
          textAlign: "left",
          width: "100%",

          "@media (max-width:1300px)": {
            width: "100%",
            margin: "0 auto",
          },

          "@media (min-width:1300px)": {
            maxWidth: "1280px",
            width: "100%",
          },

          "& > tbody": {
            "& > tr": {
              textAlign: "left",
              "@media (max-width:960px)": {
                textAlign: "center",
              },
              "& > td": {
                "@media (max-width:960px)": {
                  padding: "0px 16px",
                },

                "@media (max-width:1300px)": {
                  width: "80%",
                  margin: "0 auto",
                },

                "& figure": {
                  width: "auto !important",
                  padding: "1% 3% 1% 0%!important",
                  "@media (max-width:900px)": {
                    width: "auto !important",
                    padding: "0 1%",
                  },
                },
              },
            },
          },
        },
      },
      "& > figure:nth-of-type(2)": {
        "& table": {
          "& tr:nth-child(1)": {
            "& td": {
              "@media (max-width:960px)": {
                alignItems: "center",
                display: "flex",
                justifyContent: "left",
              },
              "@media (max-width:1300px)": {
                width: "80%",
                margin: "0 auto",
              },
            },
          },
        },
      },
      "& > figure:nth-of-type(3)": {
        "& > table": {
          "& > tbody": {
            "& > tr": {
              "& > td": {
                marginLeft: "auto!important",
                marginRight: "auto!important",
                textAlign: "left",
                width: "100%",
                maxWidth: "1280px",
                flexWrap: "wrap",
                boxSizing: "border-box",

                "@media (max-width:1300px)": {
                  width: "80%",
                  margin: "0 auto",
                },
                "@media (min-width:1300px)": {
                  maxWidth: "1280px",
                  width: "100%",
                  padding: "5% 0 7%",
                  position: "relative",
                },
                "@media (max-width:960px)": {
                  padding: "40px 16px",
                  textAlign: "center",
                },

                "& > h1": {
                  width: "100%",
                  marginBottom: "40px",
                },
              },
            },

            "& > tr:nth-of-type(1)": {
              background: `url(${bg}) left top / cover`,
              "& td": {
                display: "flex",
                "@media (max-width:960px)": {
                  padding: "40px 20px",
                  textAlign: "center",
                },
              },
            },

            "& > tr:nth-of-type(2)": {
              "& td": {
                display: "flex",
              },
            },
            "& > tr:nth-of-type(3)": {
              background: `url(${bg}) left top / cover`,
              "& td": {
                display: "flex",
                "& p:nth-child(1)": {
                  fontSize:
                    "calc(38px + (17 - 12) * ((100vw - 300px) / (1920 - 300)))!important",
                  marginBottom: "0.25em",
                  color: "#99784c",
                  fontWeight: "100",
                  fontFamily: "'HelveticaNeue-thin'!important",
                  textAlign: "left",
                  maxWidth: "100%",

                  "@media (max-width: 960px)": {
                    fontSize:
                      "calc(27px + (17 - 12) * ((100vw - 300px) / (1920 - 300)))!important",
                  },
                },
                "& p:nth-child(3)": {
                  fontSize:
                    "calc(38px + (17 - 12) * ((100vw - 300px) / (1920 - 300)))!important",
                  marginBottom: "0.25em",
                  color: "#99784c",
                  fontWeight: "100",
                  fontFamily: "'HelveticaNeue-thin'!important",
                  textAlign: "left",
                  "@media (max-width: 960px)": {
                    fontSize:
                      "calc(27px + (17 - 12) * ((100vw - 300px) / (1920 - 300)))!important",
                  },
                },
                "& p": {
                  width: "100%",
                  maxWidth: "900px",
                  textAlign: "left",
                },
              },
            },
          },
        },
      },
    },

    siteplan: {
      "& > ol": {
        float: "left",
      },
      "& > ol:nth-child(1)": {
        width: "75%",
        columns: 3,
      },
      "& > ol:nth-child(2)": {
        width: "25%",
        columns: 1,

        "&  > h4, & > p": {
          color: "#6d6e71",
        },
      },
      "& h4": {
        marginBlockEnd: "0em",
      },

      "@media (max-width:991px)": {
        "& > ol:nth-child(1)": {
          width: "100%",
          columns: 1,
        },
        "& > ol:nth-child(2)": {
          marginTop: "30px",
          width: "100%",
          columns: 1,
        },
      },
    },
  })
);

export default function TransitionsModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const openModal = useSelector((state: any) => state.modal.openModal);
  const source = useSelector((state: any) => state.modal.source);
  const type = useSelector((state: any) => state.modal.type);
  const [noCenter, setNoCenter] = useState("");
  const mobileScreen = useMediaQuery("(max-width: 960px)");
  let tempSource = "";

  const handleClose = () => {
    dispatch(modalActions.closeModal());
  };

  useEffect(() => {
    if (type === "bedroom_3" || type === "bedroom_2") {
      setNoCenter("no-center");
    }
  }, [type]);

  const contentType = (type: string, source: any) => {
    switch (type) {
      case "img":
        return (
          <div className={classes.imgWrapper}>
            <img className={classes.img} src={source} alt="template" />
          </div>
        );
      case "table":
        return (
          <>
            <div className={`tableWrapper`}>{parse(source)}</div>
          </>
        );
      case "video":
        return (
          <div className={classes.imgWrapper}>
            <video
              controls
              autoPlay
              className={classes.img}
              src={source}
              controlsList="nodownload nopictureinpicture noremoteplayback"
            />
          </div>
        );
      case "endorsement":
          return (
            <div className={classes.endorseWrapper}>
              <h1>&ldquo;{source.title}&rdquo;</h1>
              <p>{parse(source.description)}</p>
              <video
                style={{width: '100%'}}
                controls
                autoPlay
                className={classes.img}
                src={source.video}
                controlsList="nodownload nopictureinpicture noremoteplayback"
              />
              <a href={source.link} target="_blank">View Article</a>
            </div>
        );
      case "floorplan":
        return (
          <div className={classes.floorplan}>
            {source.Name !== "Roof Plan" ? (
              <>
                <h1>Site Plan</h1>
                <div className="masterroof">
                  <div>
                    <ol>
                      {source.Legend.map((item, i) => {
                        if (i < 26) {
                          //make font size same
                          return (
                            <p>
                              <span>{i + 1}</span> {item.Name}
                            </p>
                          );
                        }
                        return null;
                      })}
                    </ol>
                    <ol>
                      {source.Legend.map((item, i) => {
                        if (i >= 26 && 34 >= i) {
                          return (
                            <p>
                              <span>{i + 1}</span> {item.Name}
                            </p>
                          );
                        }
                        return null;
                      })}
                      <h4>CLUBHOUSE (ON 2ND STOREY)</h4>
                      {source.Legend.map((item, i) => {
                        if (i >= 35 && 40 >= i) {
                          return (
                            <p>
                              <span>{i + 1}</span> {item.Name}
                            </p>
                          );
                        }
                        return null;
                      })}
                      <h4 className="special">OTHERS</h4>
                      {source.Legend.map((item, i) => {
                        var chr = String.fromCharCode(65 + (i - 41));
                        if (i >= 41) {
                          return (
                            <p className="special">
                              <span>{chr}</span> {item.Name}
                            </p>
                          );
                        }
                        return null;
                      })}
                    </ol>
                  </div>
                  <div>
                    <img
                      className={classes.img}
                      src={`${process.env.REACT_APP_BACKEND_URL}${
                        source.SiteplanBackground.url
                      }`}
                      alt={source.Name}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="roofplan">
                <div>
                  <h1>Roof Plan</h1>
                  <img
                    className={classes.img}
                    src={`${process.env.REACT_APP_BACKEND_URL}${
                      source.SiteplanBackground.url
                    }`}
                    alt={source.Name}
                  />
                </div>
                <div className={classes.siteplan}>
                  <ol>
                    {source.Legend.map((item, i) => {
                      if (i < 11) {
                        return (
                          <p>
                            <span>{i + 1}</span> {item.Name}
                          </p>
                          // <p className='special'>
                          //   <li>{item.Name}</li>
                          // </p>
                        );
                      }
                      return null;
                    })}
                  </ol>
                  <ol>
                    <h4 className="special">OTHERS</h4>
                    {source.Legend.map((item, i) => {
                      var chr = String.fromCharCode(65 + (i - 11));
                      if (i >= 11) {
                        return (
                          //make font size same
                          <p className="special">
                            <span>{chr}</span> {item.Name}
                          </p>
                          // <li>
                          //   <span>{chr}</span>
                          //   {item.Name}
                          // </li>
                        );
                      }
                      return null;
                    })}
                  </ol>
                </div>
              </div>
            )}
          </div>
        );
      case "booklet":
        return (
          <>
            <div className={classes.iframeContainer}>
              {/* <Iframe
                url='https://online.anyflip.com/wljyx/ekvl/index.html'
                width='100%'
                height='100%'
              /> */}
              <Iframe
                url={"https://online.anyflip.com/wljyx/ekvl/mobile/index.html"}
                width="100%"
                height="100%"
              />
              <div className={classes.btnDownload}>
                <ButtonStandard type={"pdf"} />
              </div>
            </div>
          </>
        );
      case "locationmap":
        if (source === "shopping & dining") {
          source = "shopping";
        }

        return (
          <>
            <div className={classes.iframeContainer}>
              {/* <Iframe
                url={
                  "https://www.perfect-ten.com.sg/static/map/index.html?location=" +
                  source
                }
                width='100%'
                height='100%'
              /> */}
              {/* <Iframe
                            url={'https://perfect-ten.com.sg/map/index.html?location=' + source}
                            width="100%"
                            height="100%"
                        /> */}
              {mobileScreen === true ? (
                <Iframe
                  url={
                    "https://www.perfect-ten.com.sg/static/map/index-mobile.html?location=" +
                    source
                  }
                  width="100%"
                  height="100%"
                />
              ) : (
                <Iframe
                  url={
                    "https://www.perfect-ten.com.sg/static/map/index.html?location=" +
                    source
                  }
                  width="100%"
                  height="100%"
                />
              )}
            </div>
          </>
        );

      case "bedroom_3":
        return (
          <>
            <div className={`bedroom3Wrapper ${classes.bedroomWrapper}`}>
              {parse(source.Provision)}
            </div>
          </>
        );

      case "bedroom_2":
        return (
          <>
            <div className={`bedroom2Wrapper ${classes.bedroomWrapper}`}>
              {parse(source.Provision)}
            </div>
          </>
        );

      case "ipm":
        return (
          <>
            <div className={classes.iframeContainer}>
              {source != undefined ? (
                <Iframe
                  url={
                    source.indexOf("2BR") !== -1
                      ? "https://www.perfect-ten.com.sg/api/uploads/2_BR_26d1f938b5/index.html#" +
                        source.replace("2BR_", "")
                      : "https://www.perfect-ten.com.sg/api/uploads/3_BR_ac9fa0673b/index.html#" +
                        source.replace("3BR_", "")
                  }
                  width="100%"
                  height="100%"
                  className={classes.iframeStyle}
                />
              ) : null}
            </div>
          </>
        );

      case "gallery":
        return (
          <>
            <div className={classes.img_swipe}>
              <SwiperImage slides={source} type={"local"} />
            </div>
          </>
        );
      case "galleryLocal":
        return (
          <>
            <div className={classes.img_swipe}>
              <SwiperGallery slides={source} />
            </div>
          </>
        );
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal} ${noCenter}`}
      open={openModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
        style: { backgroundColor: "rgba(255,255,255,0.9)" },
      }}
    >
      <Fade in={openModal}>
        <div className={classes.paper}>
          {contentType(type, source)}
          <div
            className={
              type === "booklet" ? classes.btnClose_Ebooklet : classes.btnClose
            }
            onClick={() => {
              handleClose();
            }}
          >
            <img className={classes.img} src={btnClose} alt="close button" />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
