const keyMirror = require('fbjs/lib/keyMirror')

export const ActionTypes = keyMirror({
    USER_LOGIN: undefined,
    USER_LOGOUT: undefined,
    FETCH_REPOSITORIES: undefined,
    CANCEL_FETCH: undefined,
    SHOW_ALERT: undefined,
    HIDE_ALERT: undefined,
})

export const modalConstants = {
    OPEN_MODAL_REQUEST : 'OPEN_MODAL_REQUEST',
    OPEN_MODAL_SUCCESS : 'OPEN_MODAL_SUCCESS',
    OPEN_MODAL_FAILURE : 'OPEN_MODAL_FAILURE',
    CLOSE_MODAL_SUCCESS: 'CLOSE_MODAL_SUCCESS'
}

export const cardConstants = {
    ACTIVE_CARD_REQUEST : 'ACTIVE_CARD_REQUEST'
}

export const ApiBaseUrl = require('./url.dev').ApiBaseUrl
