import React from "react";
import gql from "graphql-tag";
import Wrapper from "components/Wrapper";
import GlobalNav from "components/GlobalNav";
import { makeStyles } from "@material-ui/core/styles";
import Iframe from "react-iframe";
import Grid from "@material-ui/core/Grid";
import Footer from "../components/Footer";
import Container from "@material-ui/core/Container";
import LocationCard from "../components/LocationCard";
import HeaderBar from "../components/HeaderBar";
import bg from "../assets/images/location/bg.png";
import { Query } from "react-apollo";
import LoadingSpinner from "../components/LoadingSpinner";
import HandLogo from "../components/HandLogo";
import IntersectionObserver from "../components/IntersectionObserver";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const QUERY = gql`
  query {
    location {
      Name
      Ipm {
        url
      }
      Map {
        url
      }
      NormalGallery {
        Name
        Description
        Link
        Image {
          url
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  iframeStyle: {
    width: "100%",
    height: "100%",
    border: "none",
  },
  iframeContainer: {
    width: "100vw",
    height: "100vh",
    position: "relative",
    border: 0,
    "@media (max-width:960px)": {
      height: "70vh",
      minHeight: "400px",
    },
  },
  iframe: {
    width: "100%",
    height: "100%",
  },
  sectionBg: {
    background: `url(${bg}) left top / cover`,
    minHeight: "100vh",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "@media (max-width:960px)": {
      minHeight: "auto",
    },
  },
  container: {
    padding: "70px 0px",
    "@media (max-width:960px)": {
      marginTop: "25px",
    },
    "@media (max-width:800px)": {
      justifyContent: "center",
    },
  },
  handWrapper: {
    position: "absolute",
    bottom: "13vh",
    left: "50%",
    transform: "translate(-50%,0)",
    width: "190px",
    "@media (max-width:960px)": {
      width: "100px",
    },
  },
}));

const Location = () => {
  const mobileScreen = useMediaQuery("(max-width: 960px)");
  const classes = useStyles();
  return (
    <Wrapper>
      <GlobalNav activeItem='location' />
      <HeaderBar />

      <Query query={QUERY}>
        {({ data: { location }, loading }) => {
          if (loading || !location) {
            return <LoadingSpinner />;
          }

          return (
            <>
              <Grid className={classes.iframeContainer}>
                {/* <Iframe
                                url={`${process.env.REACT_APP_BACKEND_URL}${location.Ipm.url.replace('.zip', '/index.html')}`}
                                className={classes.iframeStyle}
                            /> */}
                <Iframe
                  url={`https://www.perfect-ten.com.sg/static/ipm/index.html`}
                  className={classes.iframeStyle}
                />
                <div className={classes.handWrapper}>
                  <IntersectionObserver>
                    <HandLogo />
                  </IntersectionObserver>
                </div>
              </Grid>

              <div className={classes.sectionBg}>
                <Container maxWidth='lg'>
                  <Grid container className={classes.container}>
                    {location.NormalGallery.map((gallery, i) => {
                      return (
                        <Grid item md={4} key={`gallery__${gallery.Name}`}>
                          <LocationCard
                            id={i}
                            img={`${process.env.REACT_APP_BACKEND_URL}${
                              gallery.Image.url
                            }`}
                            source={
                              gallery.Name !== "Parks & Recreation"
                                ? gallery.Name.toLowerCase()
                                : "parks"
                            }
                            header={gallery.Name}
                            text={gallery.Description}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Container>
              </div>

              <Grid className={classes.iframeContainer}>
                {/* <Iframe
                                url={`${process.env.REACT_APP_BACKEND_URL}${location.Map.url.replace('.zip', '/index.html')}`}
                                className={classes.iframeStyle}
                            /> */}
                {/* <Iframe
                  url={`https://www.perfect-ten.com.sg/static/map/index.html?location=all`}
                  className={classes.iframeStyle}
                /> */}
                {/* <Iframe
                  url={`https://www.perfect-ten.com.sg/static/map/index.html?location=all`}
                  className={classes.iframeStyle}
                /> */}

                {mobileScreen === true ? (
                  <Iframe
                    url={`https://www.perfect-ten.com.sg/static/map/index-mobile.html?location=all`}
                    className={classes.iframeStyle}
                  />
                ) : (
                  <Iframe
                    url={`https://www.perfect-ten.com.sg/static/map/index.html?location=all`}
                    className={classes.iframeStyle}
                  />
                )}
                {/* <Iframe
                                url={`https://www.perfect-ten.com.sg/static/map/?location=all`}
                                className={classes.iframeStyle}
                            /> */}
                <div className={classes.handWrapper}>
                  <IntersectionObserver>
                    <HandLogo text='yes' />
                  </IntersectionObserver>
                </div>
              </Grid>
            </>
          );
        }}
      </Query>
      <Footer />
    </Wrapper>
  );
};

export default Location;
