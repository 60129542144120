import React from 'react';
import gql from "graphql-tag";
import Wrapper from 'components/Wrapper';
import GlobalNav from 'components/GlobalNav';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { modalActions } from '../actions/modalActions';
import { Query } from 'react-apollo';
import Button from '../components/Button';
import Footer from '../components/Footer';
import HeaderBar from '../components/HeaderBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import H1 from '../components/h1';
import bg from '../assets/images/overview/bg.png';
import { useDispatch } from 'react-redux'
import video_thumbnail from '../assets/images/overview/video_thumbnail.jpg'
import video_thumbnail_mobile from '../assets/images/overview/video_thumbnail_mobile.png'
import parse from 'html-react-parser';
import pause from '../assets/images/pause.png'
import LoadingSpinner from '../components/LoadingSpinner'

const QUERY = gql`
  query {
    overview {
      HeaderTitle
      HeaderDescription
      HeaderImage {
        url
      }
      Video {
        url
      }
      ProjectDetails
      SectionGallery {
        Name
        Description
        Link
        Images {
          url
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
    buildingImg : {
        width:'90%',
        padding:'4%',
        position:'absolute',
        top:'45%',
        transform:'translate(0%,-50%)',
        '@media (max-width:960px)': {
            top:'auto',
            position:'static',
            transform:'none',
            margin: '0 auto',
            width:'100%',
            padding:'0',
        }
    },
    buildingImgContainer:{
        position:'relative',
        '@media (max-width:960px)': {
            order:2,
            marginTop:'40px',
            marginBottom:'50px',
            textAlign:'center'
        }
    },
    buildingContainer : {
        background: `url(${bg}) left top / cover`,
        minHeight:'100vh',
        position:'relative',
        '@media (max-width:960px)': {
            minHeight:'auto',
        }
    },
    textContainerBg:{
        position:'relative',
        '@media (max-width:960px)': {
            textAlign:'center',
            margin:'70px auto 0'
        }
    },
    textWrapper : {
        position:'absolute',
        top:'50%',
        transform: 'translate(0%, -50%)',
        width:'400px',
        '@media (max-width:960px)': {
            position:'relative',
            top:'0',
            transform:'none',
            width:'100%',
        }
    },
    textContainer : {
        position:'relative',
        '@media (max-width:960px)': {
            marginTop:'10px',
            textAlign:'left',
            marginBottom:'20px'
        }
    },
    containerLast:{
        marginBottom: '0px',
    },
    videoThumbnailWrapper: {
        width:'100%',
        position:'relative'
    },
    videoPreview : {
        width:'100%',
        cursor:'pointer'
    },
    btnPause : {
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%,-50%)',
        width:'100px',
        '& img' : {
            width:'100%'
        }
    },
    textBgWrapper: {
        position: 'absolute',
        top:'40%',
        transform: 'translate(0%, -50%)',
        width:'25vw',
        minHeight: '222px',

        '@media (max-width:960px)': {
            minHeight: 'auto',
            position: 'static',
            transform: 'none',
            width: '100%',
        },

        '& h1' : {
            width:'24vw',

            '@media (max-width:960px)': {
                width: '80%',
                margin: '0 auto',
            }
        },
    },
    imgWrapper : {
        position:'relative',
        width:'90%',
        margin:'10% 0',
        '& img' : {
            width:'100%'
        },
        '@media (max-width:960px)': {
            width:'100%',
        }
    }
}))

const Overview = () => {
    const classes           = useStyles();
    const dispatch          = useDispatch();
    const mobileScreen      = useMediaQuery('(max-width:960px)');

    const videoOnClick = (type, source) => {
        dispatch(modalActions.openModal(type,source))
    }

    return (
        <Wrapper>
            <GlobalNav activeItem="overview"/>
            <HeaderBar />

            <Query query={QUERY}>
                {({ data: { overview }, loading }) => {

                if (loading || !overview) {
                    return (
                        <LoadingSpinner />
                    );
                }

                return(
                    <>
                        <Grid container className={`${classes.buildingContainer} contentWrapper`}>

                            <Grid item md={8} className={classes.buildingImgContainer}>
                                <img src={`${process.env.REACT_APP_BACKEND_URL}${overview.HeaderImage.url}`} className={classes.buildingImg} alt={overview.HeaderTitle} /> 
                                {/* } */}
                                <div className={`artistWrapper_grey_overview`}>
                                    <p>
                                        Artist's Impression
                                    </p>
                                </div>
                            </Grid>
                            <Grid item md={4} className={classes.textContainerBg}>
                                <div className={classes.textBgWrapper}>
                                    {parse(overview.HeaderTitle)}
                                    <div className="HeaderOverview">{parse(overview.HeaderDescription)}</div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className={`marginTop_991 contentWrapper`}>
                            <Grid item md={7}>
                                <div className={classes.imgWrapper}>
                                    <img src={`${process.env.REACT_APP_BACKEND_URL}${overview.SectionGallery[0].Images[0].url}`} alt="project"/>
                                    <div className={`artistWrapper_white`}>
                                        <p>
                                            Artist's Impression
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={5} className={classes.textContainer}>
                                <div className={`${classes.textWrapper} textLeft_991`}>
                                    {parse(overview.SectionGallery[0].Description)}
                                    <Button text={'PROJECT DETAILS'} type={'table'} source={overview.ProjectDetails}/>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.containerLast}>
                            <Grid item md={12} className={classes.videoThumbnailWrapper} onClick={() => videoOnClick('video', process.env.REACT_APP_BACKEND_URL + overview.Video.url)}>
                                {mobileScreen === true ?
                                <>
                                    <img src={video_thumbnail_mobile} className={classes.videoPreview}  alt="video"/>
                                    <div className={classes.btnPause}>
                                        <img src={pause} alt="Pause"/>
                                    </div>
                                </>
                                 :
                                 <>
                                    <img src={video_thumbnail} className={classes.videoPreview}  alt="video"/>
                                    <div className={classes.btnPause}>
                                        <img src={pause} alt="Pause"/>
                                    </div>
                                </>
                                }
                            </Grid>
                        </Grid>
                    </>)
                }}
            </Query>
            <Footer />
        </Wrapper>
    )
}

export default Overview