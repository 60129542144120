import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import hand from '../assets/images/hand.png'
import { motion } from "framer-motion"
import { IntersectionContext } from '../components/IntersectionObserver';

const useStyles =  makeStyles(() => ({
    img: {
        width:'100%'
    },
    handWrapper :{
        textAlign:'center',
        '& h3' :{
            color : '#fff',
            display:'none',
            textShadow:'1px 1px #8c8c8a'
        }
    },
    text: {
        display:'block!important'
    }

}));

const HandTransistionVariants = {
    show:{
        x:["50%","-50%"],
        transition: {
            yoyo:Infinity,
            duration:4,
        },
        opacity: 1,
    },
    hidden: {
        x:["50%","-50%"],
        transition: {
            duration:4,
            delay:9,
        },
        opacity:0,
    }
};



const HandLogo = (props:any) => {
    const classes = useStyles();
    const { inView } = React.useContext(IntersectionContext);

    return( 
        <motion.div
            variants={HandTransistionVariants} 
            animate={inView ? "hidden":"show"}
            className={classes.handWrapper}
            >
            <img 
                src={hand}
                className={classes.img}
                alt="hand_logo" 
            />
            <h3 className={(props.text === 'yes') ? classes.text : null}>Zoom In / Out</h3>
        </motion.div>
    )
}

export default HandLogo;