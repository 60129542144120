import { modalConstants } from '../constants/index'

const initialState = { openModal: false,type:null,source:null};

export function modal(state = initialState,action:any) {
    switch(action.type) {
        case modalConstants.OPEN_MODAL_SUCCESS :
            return {
                ...state,
                openModal:true,
                type:action.modalType,
                source:action.source
            }
        case modalConstants.CLOSE_MODAL_SUCCESS:
            return {
                ...state,
                openModal:false,
            }
        default:
            return state;
    }

}