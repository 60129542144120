import { cardConstants } from '../constants'

export const cardActions = {
    activeCard
}

//@Desc Card
function activeCard(cardId:number) {
    return {
        type: cardConstants.ACTIVE_CARD_REQUEST,
        cardId,
    }
}