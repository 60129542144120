import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import gql from "graphql-tag";
import { Query } from 'react-apollo';
// import parse from 'html-react-parser';
import { Menu } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const QUERY = gql`
query {
  global {
    Logo {
      url
    }
    Menu {
      Name
    }
    MastheadBackground {
      url
    }
    SectionBackground {
      url
    }
    BoxLightBackground {
      url
    }
    BoxDarkBackground {
      url
    }
    Footer {
      Name
      Link
      Logo {
        url
      }
    }
  }
}
`;

const useStyles = makeStyles((theme) => ({
  menu : {
      borderBottom: 'none!important',
      "&:hover" : {
        fontWeight:'700'
    }
  },
  menuItem : {
      margin:'0 10px',
      border: 'none!important',
      "&:hover" : {
          fontWeight:'700'
      }
  },
  logoImg : {
      width: '100%',
  },
}));

export const Navigation = ({activeItem}) => {
  const dispatch    = useDispatch();
  const classes     = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeItem])

  return (
    <Query query={QUERY} id={null}>
      {({ data: { global }, loading }) => {
        if (loading || !global) {
          return <div>Loading ...</div>;
        }

        return (
          <div>
              <div>
                <Menu pointing secondary className={classes.menu}>
                {global.Menu.map((name) => {
                  return <Menu.Item
                        name={name.Name}
                        key={name.Name}
                        active={activeItem === name.Name}
                        onClick={() => dispatch(push('/'+name.Name.replace(' ', '').toLowerCase()))}
                        link={true}
                        className={`${classes.menuItem} ${(activeItem === (name.Name).replace(' ', '').toLowerCase()) ? 'active': ''}`}
                    />
                  })}
                </Menu>
              </div>
          </div>
        );
      }}
    </Query>
  );
};

export const Logo = () => {
  return (
    <Query query={QUERY} id={null}>
      {({ data: { global }, loading }) => {
        if (loading || !global) {
          return <div>Loading ...</div>;
        }
        return (
          <img src={`${process.env.REACT_APP_BACKEND_URL}${global.Logo.url}`} alt={global.Logo.Name} />
        );
      }}
    </Query>
  );
};

export const Footer = () => {
  return (
    <Query query={QUERY} id={null}>
      {({ data: { global }, loading }) => {
        if (loading || !global) {
          return <div>Loading ...</div>;
        }
        return (
          <>
            {global.Footer.map((footer) => {
                  return (
                      <figure key={`footer__${footer.Name}`}>
                          <a href={`${footer.Link}`} target="_blank" rel="noopener noreferrer">
                            <img src={`${process.env.REACT_APP_BACKEND_URL}${footer.Logo.url}`} alt={footer.Name} />
                          </a>
                      </figure>
                    )
                  })}
          </>
        );
      }}
    </Query>
  );
};