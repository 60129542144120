import * as React from "react"

function N(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 17.23 19.98"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path" transform="translate(2.89 2.1)">
          <path
            className="prefix__cls-12"
            d="M9.78 0v12.81L1.62 0H0v15.79h1.68V3l8.15 12.79h1.62V0H9.78z"
          />
        </clipPath>
        <style>{".prefix__cls-12{fill:#99784c}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-12"
            d="M12.67 2.1v12.8L4.51 2.1H2.89v15.78h1.68V5.09l8.15 12.79h1.62V2.1h-1.67z"
          />
        </g>
      </g>
    </svg>
  )
}

export default N
