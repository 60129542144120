import React from "react";
import Wrapper from "components/Wrapper";
import gql from "graphql-tag";
import GlobalNav from "components/GlobalNav";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Footer from "../components/Footer";
import H1 from "../components/h1";
import Button from "../components/Button";
import Container from "@material-ui/core/Container";
import HeaderBar from "../components/HeaderBar";
import { Fade } from "react-awesome-reveal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwiperImage from "../components/SwiperImage";
import { Query } from "react-apollo";
import parse from "html-react-parser";
import LoadingSpinner from "../components/LoadingSpinner";

// image
import section1_bg from "../assets/images/amenities/section1_bg.png";
import bg from "../assets/images/overview/bg.png";
import galleryThumbnail1 from "../assets/images/amenities/galleryThumbnail1.png";
import galleryThumbnail2 from "../assets/images/amenities/galleryThumbnail2.png";
import galleryThumbnail3 from "../assets/images/amenities/galleryThumbnail3.png";
import galleryThumbnail4 from "../assets/images/amenities/galleryThumbnail4.png";
import galleryThumbnail5 from "../assets/images/amenities/galleryThumbnail5.png";
import galleryThumbnail6 from "../assets/images/amenities/galleryThumbnail6.png";
import galleryThumbnail7 from "../assets/images/amenities/galleryThumbnail7.png";
import galleryThumbnail8 from "../assets/images/amenities/galleryThumbnail8.png";
import galleryThumbnail9 from "../assets/images/amenities/galleryThumbnail9.png";
import galleryThumbnail10 from "../assets/images/amenities/galleryThumbnail10.png";
import galleryThumbnail11 from "../assets/images/amenities/galleryThumbnail11.png";
import galleryThumbnail12 from "../assets/images/amenities/galleryThumbnail12.png";
import galleryThumbnail13 from "../assets/images/amenities/galleryThumbnail13.png";
import galleryThumbnail14 from "../assets/images/amenities/galleryThumbnail14.png";

import section1_img_mobile from "../assets/images/amenities/section1_img_mobile.png";

import { useDispatch } from "react-redux";
import { modalActions } from "../actions/modalActions";

const QUERY = gql`
  query {
    amenity {
      Name
      HeaderImage {
        url
      }
      HeaderTitle
      HeaderDescription
      SectionGallery {
        Name
        Description
        Link
        Images {
          url
        }
      }
      Siteplan {
        Name
        SiteplanBackground {
          url
        }
        Legend {
          Name
        }
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  section1_bg: {
    width: "100%",
    position: "absolute",
    bottom: "0",
    "@media (max-width:960px)": {
      position: "static",
      order: 2,
    },
  },
  section_header: {
    margin: "6% 0 2%",
    textAlign: "center",
  },
  sectionContainer: {
    position: "relative",
    background: `url(${section1_bg}) left top / cover`,
    minHeight: "100vh",
    "@media (max-width:960px)": {
      minHeight: "auto",
    },
  },
  swiperContainer: {
    "@media (max-width:960px)": {
      display: "block",
      paddingTop: "40px",
      paddingBottom: "80px",
    },
  },
  sectionBg: {
    background: `url(${bg}) left top / cover`,
  },
  buildingContainer: {
    background: `url(${bg}) left top / cover`,
    minHeight: "100vh",
    position: "relative",
  },
  textWrapper: {
    position: "absolute",
    top: "50%",
    transform: "translate(0%, -50%)",
    maxWidth: "28vw",
    width: "90%",
    "@media (max-width:960px)": {
      position: "static",
      top: "auto",
      transform: "none",
      width: "100%",
      maxWidth: "100%",
    },
  },
  section1_textWrapper: {
    position: "absolute",
    top: "40%",
    transform: "translate(-50%, -50%)",
    width: "28vw",
    maxWidth: "100%",
    minHeight: "222px",
    left: "25%",

    "@media (max-width:960px)": {
      position: "static",
      top: "auto",
      transform: "none",
      left: "auto",
      textAlign: "center",
      marginTop: "70px",
      width: "100%",

      "& h1": {
        width: "80%",
        margin: "0 auto",
      },
    },
  },
  textContainer: {
    position: "relative",
  },
  textWrapperRight: {
    position: "absolute",
    top: "50%",
    transform: "translate(0%, -50%)",
    width: "380px",
    right: 0,
    "@media (max-width:960px)": {
      position: "static",
      top: "0",
      transform: "none",
      width: "100%",
      right: "0",
    },
  },
  contentImage: {
    "@media (max-width:960px)": {
      paddingTop: "40px",
      paddingBottom: "40px",
    },
  },
  section_center: {
    textAlign: "center",
  },
  galleryGrid: {
    paddingRight: "20px",
    width: "100%",
    "& img": {
      width: "100%",
      paddingBottom: "15px",
      cursor: "pointer",
    },
    "@media (max-width:960px)": {
      paddingRight: "0px",
    },
  },
  galleryGrid_paddingLeft: {
    paddingLeft: "10px",
    "@media (max-width:960px)": {
      paddingLeft: "0px",
      width: "100%",
    },
  },
  galleryGrid_paddingRight: {
    paddingRight: "10px",
    "@media (max-width:960px)": {
      paddingRight: "0px",
      width: "100%",
    },
  },
  galleryGrid_nopadding: {
    paddingRight: "0px",
    "& img": {
      width: "100%",
      paddingBottom: "0px",
      cursor: "pointer",
    },
    "@media (max-width:960px)": {
      paddingRight: "0px",
    },
  },
  galleryGrid_nopaddingBottom: {
    paddingRight: "20px",
    "& img": {
      width: "100%",
      paddingBottom: "0px",
      cursor: "pointer",
    },
    "@media (max-width:960px)": {
      paddingRight: "0px",
    },
  },
  contentContainer: {
    "@media (max-width:960px)": {
      overflow: "hidden",
      maxWidth: "100%",
    },
  },
  section1_bgWrapper: {
    "@media (max-width:960px)": {
      order: 2,
    },
  },
  swiperContainerReverse: {
    "@media (max-width:960px)": {
      display: "block",
      paddingBottom: "40px",
      paddingTop: "80px",
      transform: " scaleY(-1)",
    },
  },
}));

const Amenities = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileScreen = useMediaQuery("(max-width:960px)");

  return (
    <Wrapper>
      <GlobalNav activeItem='amenities' />
      <HeaderBar />

      <Query query={QUERY}>
        {({ data: { amenity }, loading }) => {
          if (loading || !amenity) {
            return <LoadingSpinner />;
          }

          return (
            <>
              <Grid container className={`${classes.sectionContainer}`}>
                <Grid item className={classes.section1_bgWrapper}>
                  {mobileScreen === true ? (
                    <img
                      src={section1_img_mobile}
                      className={classes.section1_bg}
                      alt='bg_img'
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}${
                        amenity.HeaderImage[0].url
                      }`}
                      className={classes.section1_bg}
                      alt='bg_img'
                    />
                  )}
                </Grid>
                <Grid item md={4}>
                  <div
                    className={`${
                      classes.section1_textWrapper
                    }  contentWrapper`}
                  >
                    {parse(amenity.HeaderTitle)}
                    {parse(amenity.HeaderDescription)}
                  </div>
                </Grid>
              </Grid>

              {amenity.SectionGallery.map((section, i) => {
                return i % 2 === 0 ? (
                  <Grid
                    container
                    key={section.Name}
                    className={`${classes.swiperContainer} contentWrapper`}
                  >
                    <Grid item md={8}>
                      {section.Images.length > 1 ? (
                        <div className={`sectionImg_left`}>
                          <SwiperImage
                            slides={section.Images}
                            direction={"left"}
                          />
                        </div>
                      ) : (
                        <div className={`sectionImg_leftNomargin`}>
                          <img
                            src={`${process.env.REACT_APP_BACKEND_URL}${
                              section.Images[0].url
                            }`}
                            className={`img`}
                            alt='architecture'
                          />
                          <div className={`artistWrapper_white`}>
                            <p>Artist's Impression</p>
                          </div>
                        </div>
                      )}
                    </Grid>
                    <Grid item md={4} className={`${classes.textContainer}`}>
                      <div className={classes.textWrapper}>
                        <H1>{section.Name}</H1>
                        {parse(section.Description)}
                        <Button
                          text={section.Link}
                          type={"floorplan"}
                          source={amenity.Siteplan[0]}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    key={section.Name}
                    className={classes.sectionBg}
                  >
                    <Grid
                      container
                      className={`contentWrapper ${classes.contentImage}`}
                    >
                      <Grid
                        item
                        md={4}
                        className={`${classes.textContainer} reverse_991`}
                      >
                        <div className={classes.textWrapperRight}>
                          <H1>{section.Name}</H1>
                          {parse(section.Description)}
                          <Button
                            text={section.Link}
                            type={"floorplan"}
                            source={amenity.Siteplan[1]}
                          />
                        </div>
                      </Grid>
                      <Grid item md={8}>
                        {section.Images.length > 1 ? (
                          <div className={`sectionImg_right swiper_margin991`}>
                            <SwiperImage
                              slides={section.Images}
                              direction={"right"}
                            />
                          </div>
                        ) : (
                          <div className={`sectionImg_right`}>
                            <img
                              src={`${process.env.REACT_APP_BACKEND_URL}${
                                section.Images[0].url
                              }`}
                              className={`img`}
                              alt='architecture'
                            />
                            <div className={`artistWrapper_white_right`}>
                              <p>Artist's Impression</p>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}

              <Container maxWidth='lg' className='containerLast'>
                <Grid item md={12} className={classes.section_header}>
                  <H1>Gallery</H1>
                </Grid>
                <Grid item container md={12} className={classes.section_center}>
                  <Grid item md={4} className={classes.galleryGrid}>
                    <Fade direction='up'>
                      <img
                        src={galleryThumbnail1}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 1))
                        }
                      />
                      <img
                        src={galleryThumbnail2}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 2))
                        }
                      />
                    </Fade>
                  </Grid>
                  <Grid item container md={8} className={classes.galleryGrid}>
                    <Fade direction='up'>
                      <img
                        src={galleryThumbnail3}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 3))
                        }
                      />
                    </Fade>
                    <Grid
                      item
                      md={6}
                      className={classes.galleryGrid_paddingRight}
                    >
                      <Fade direction='up'>
                        <img
                          src={galleryThumbnail4}
                          alt='gallery'
                          onClick={() =>
                            dispatch(modalActions.openModal("galleryLocal", 4))
                          }
                        />
                      </Fade>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className={classes.galleryGrid_paddingLeft}
                    >
                      <Fade direction='up'>
                        <img
                          src={galleryThumbnail5}
                          alt='gallery'
                          onClick={() =>
                            dispatch(modalActions.openModal("galleryLocal", 5))
                          }
                        />
                      </Fade>
                    </Grid>
                  </Grid>
                  <Grid item container md={8} className={classes.galleryGrid}>
                    <Fade direction='up'>
                      <img
                        src={galleryThumbnail6}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 6))
                        }
                      />
                    </Fade>
                    <Grid
                      item
                      md={6}
                      className={classes.galleryGrid_paddingRight}
                    >
                      <Fade direction='up'>
                        <img
                          src={galleryThumbnail9}
                          alt='gallery'
                          onClick={() =>
                            dispatch(modalActions.openModal("galleryLocal", 9))
                          }
                        />
                      </Fade>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      className={classes.galleryGrid_paddingLeft}
                    >
                      <Fade direction='up'>
                        <img
                          src={galleryThumbnail10}
                          alt='gallery'
                          onClick={() =>
                            dispatch(modalActions.openModal("galleryLocal", 10))
                          }
                        />
                      </Fade>
                    </Grid>
                  </Grid>
                  <Grid item md={4} className={classes.galleryGrid}>
                    <Fade direction='up'>
                      <img
                        src={galleryThumbnail7}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 7))
                        }
                      />
                      <img
                        src={galleryThumbnail8}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 8))
                        }
                      />
                    </Fade>
                  </Grid>
                  <Grid item md={4} className={classes.galleryGrid}>
                    <Fade direction='up'>
                      <img
                        src={galleryThumbnail11}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 11))
                        }
                      />
                    </Fade>
                  </Grid>
                  <Grid item md={4} className={classes.galleryGrid}>
                    <Fade direction='up'>
                      <img
                        src={galleryThumbnail12}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 12))
                        }
                      />
                    </Fade>
                  </Grid>
                  <Grid item md={4} className={classes.galleryGrid}>
                    <Fade direction='up'>
                      <img
                        src={galleryThumbnail13}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 13))
                        }
                      />
                    </Fade>
                  </Grid>
                  <Grid item md={12} className={classes.galleryGrid}>
                    <Fade direction='up'>
                      <img
                        src={galleryThumbnail14}
                        alt='gallery'
                        onClick={() =>
                          dispatch(modalActions.openModal("galleryLocal", 14))
                        }
                      />
                    </Fade>
                  </Grid>
                </Grid>
              </Container>
            </>
          );
        }}
      </Query>
      <Footer />
    </Wrapper>
  );
};

export default Amenities;
