import { Observable } from 'rxjs'
import { Epic } from 'redux-observable'

import { ofAction } from 'typescript-fsa-redux-observable'
// for actions
import { AnyAction } from 'typescript-fsa'
import actions from 'actions/github'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'

/* import { client } from '../../services/network'          //new client instance
import { languageMutation} from '../mutation'     //mutation to do (using gql)
import { changeLanguageFulfilled, changeLanguageError} from '../actions/navigationBarActions';    //import actions to use in the epic

export const languageEpic = (action$) => {
  return action$.ofType('CHANGE_LANGUAGE')
    .mergeMap(action => client.mutate({
      mutation: languageMutation,
      variables: { id: action.id,  defaultLanguage: action.selected_language, defaultTimeZoneId: action.selected_timeZone }
    }).then(result => changeLanguageFulfilled(result))
      .catch(error => changeLanguageError(error))
  );
}; */

console.log('es')

export const fetchRepositories: Epic<AnyAction> = action$ =>
    action$.pipe(
        ofAction(actions.fetchRepositories.started),
        debounceTime(1000),
        mergeMap(param =>
            ajax
                .getJSON(
                    `https://api.github.com/search/repositories?q=+language:javascript+created:%3E2016-10-01&sort=stars&order=desc`
                )
                .pipe(
                    map(data => {
                        return actions.fetchRepositories.done({
                            params: param.payload,
                            result: { repositories: data },
                        })
                    }),
                    catchError(error =>
                        Observable.of(
                            actions.fetchRepositories.failed({
                                params: param.payload,
                                error: error,
                            })
                        )
                    )
                )
        )
    )
