import React, { useState } from "react";
import Wrapper from "components/Wrapper";
import GlobalNav from "components/GlobalNav";
import gql from "graphql-tag";
import Iframe from "react-iframe";
import { Query } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Footer from "../components/Footer";
import H1 from "../components/h1";
import Button from "../components/Button";
import Container from "@material-ui/core/Container";
import HeaderBar from "../components/HeaderBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch } from "react-redux";
import { modalActions } from "../actions/modalActions";
import LoadingSpinner from "../components/LoadingSpinner";
// images
import section1_bg from "../assets/images/floorplan/section1_bg.png";
import bg from "../assets/images/floorplan/bg_floorplan.png";
import parse from "html-react-parser";
import header_mobile from "../assets/images/floorplan/section1_img_mobile.png";
import ThreeBR from "../assets/images/3br.png";
import TwoBR from "../assets/images/2br.png";

const QUERY = gql`
  query {
    floorPlan {
      Name
      HeaderImage {
        url
      }
      HeaderTitle
      HeaderDescription
      Floorplan {
        Name
        Description
        Link
        IPM {
          Name
          File {
            url
          }
        }
        Provision
        Floorplan3D {
          url
        }
      }
      Ebrochure {
        url
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
  section1_bg: {
    width: "100%",
    height: "auto",
  },
  iframeStyle: {
    width: "100%",
    height: "50vh",
    border: "none",
  },
  section1_img: {
    "& img": {
      width: "100%",
      padding: "10%",
      "@media (max-width:960px)": {
        padding: "0%",
      },
    },
    "& p": {
      textAlign: "center",
      color: "#99784c",
    },
  },
  sectionBg: {
    background: `url(${bg}) left top / cover`,
  },
  textWrapper: {
    position: "absolute",
    top: "50%",
    transform: "translate(0%, -50%)",
    maxWidth: "28vw",
    width: "90%",
    "@media (max-width:960px)": {
      position: "relative",
      top: "auto",
      transform: "none",
    },
  },

  ebrochure_wrapper: {
    // position: "relative",
    // width: "28vw",
    // maxWidth: "100%",
    // margin: "0 auto",
    // top: "37vh",
    // left: "4vw",
    // transform: "translate(0%, -50%)",
    position: "relative",
    width: "28vw",
    maxWidth: "100%",
    margin: "0 auto",

    transform: "translate(0%, -50%)",

    "@media (max-width:960px)": {
      position: "relative",
      top: "initial",
      left: "initial",
      width: "100%",
    },
    "@media (max-width:600px)": {
      position: "relative",
      top: "5vh",
      // left: "-5vw",
      left: "5vw",

      width: "100%",
      transform: "translate(0%, -50%)",
    },
    "@media (max-width:400px)": {
      position: "relative",
      top: "5vh",
      // left: "-10vw",
      left: "5vw",

      width: "100%",
    },
  },
  section1_textWrapper: {
    position: "absolute",
    top: "40%",
    transform: "translate(-50%, -50%)",
    width: "28vw",
    minHeight: "222px",
    maxWidth: "100%",
    left: "25%",

    "& h1": {
      width: "24vw",

      "@media (max-width:960px)": {
        width: "80%",
        margin: "0 auto",
      },
    },

    "@media (max-width:960px)": {
      position: "relative",
      top: "initial",
      transform: "none",
      left: "initial",
      width: "100%",
      textAlign: "center",
      minHeight: "auto",
    },
  },
  textContainer: {
    position: "relative",
  },
  sectionContainer: {
    position: "relative",
    background: `url(${section1_bg}) left top / cover`,
    minHeight: "100vh",
    "@media (max-width:960px)": {
      minHeight: "90vh",
      padding: "0% 0 3%",
    },
  },
  textWrapperCenter: {
    position: "absolute",
    top: "50%",
    transform: "translate(0%, -50%)",
    width: "400px",
    margin: "0 auto",
    left: 0,
    right: 0,
    "@media (max-width:960px)": {
      position: "relative",
      top: "auto",
      transform: "none",
      width: "100%",
      margin: "40px 0",
    },
  },
  brochure: {
    margin: "10% 0",
    "@media (max-width:960px)": {
      marginLeft: "40%",
      top: "-45vh",
    },
    "@media (max-width:600px)": {
      marginLeft: "0%",
      top: "-45vh",
    },
  },
  section_center: {
    textAlign: "center",
  },
  section1_bgWrapper: {
    float: "right",
    position: "absolute",
    right: "6%",
    top: "50%",
    width: "77%",
    maxWidth: "100%",
    transform: "translate(0,-50%)",
    "@media (max-width:960px)": {
      order: 2,
      float: "none",
      position: "static",
      right: "auto",
      top: "auto",
      transform: "none",
      margin: "0 auto",
      marginTop: "0%",
      width: "95%",
    },
  },
  section1_textContainer: {
    "@media (max-width:960px)": {
      width: "100%",
      margin: "70px auto 30px",
    },
  },
  floorplanContainer: {
    "@media (max-width:960px)": {
      width: "100%",
    },
  },
  content1_container: {
    "@media (max-width:960px)": {
      width: "100%",
    },
  },
}));

const Floorplan = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mobileScreen = useMediaQuery("(max-width:960px)");
  const [mobileActivated, setmobileActivated] = useState("3br");

  function ModelLoaded2BR() {
    window.addEventListener("message", getMessage2BR);
  }

  function getMessage2BR(message) {
    if (message.data.ipm != undefined) {
      dispatch(modalActions.openModal("ipm", message.data.ipm));
    }
  }

  function ModelLoaded3BR() {
    window.addEventListener("message", getMessage3BR);
  }

  function getMessage3BR(message) {
    if (message.data.ipm != undefined) {
      dispatch(modalActions.openModal("ipm", message.data.ipm));
    }
  }

  return (
    <Wrapper>
      <GlobalNav activeItem="floorplan" />
      <HeaderBar />

      <Query query={QUERY}>
        {({ data: { floorPlan }, loading }) => {
          if (loading || !floorPlan) {
            return <LoadingSpinner />;
          }

          return (
            <>
              <Grid container>
                <Grid item md={12} className={classes.sectionContainer}>
                  {/* desktop */}
                  {/* <StaticCoin x={7} y={9.5} mobile={false} />
                                <StaticAlphabet text={'C'} x={20}  y={8} mobile={false} />
                                <StaticAlphabet text={'N'} x={35}  y={8} mobile={false} />
                                <StaticCoin x={50} y={10} mobile={false} />
                                <StaticAlphabet text={'R'} x={95}  y={8} mobile={false} />
                                <StaticAlphabet text={'E'} x={22}  y={25} mobile={false} />
                                <StaticCoin x={38} y={27} mobile={false} />
                                <StaticAlphabet text={'R'} x={55}  y={26} mobile={false} />
                                <StaticAlphabet text={'N'} x={5}  y={50} mobile={false} />
                                <StaticAlphabet text={'E'} x={95}  y={50} mobile={false} />
                                <StaticAlphabet text={'C'} x={85}  y={50} mobile={false} />
                                <StaticAlphabet text={'C'} x={5}  y={70} mobile={false} />
                                <StaticAlphabet text={'N'} x={25}  y={70} mobile={false} />
                                <StaticAlphabet text={'R'} x={40}  y={70} mobile={false} />
                                <StaticCoin x={95} y={70} mobile={false} />
                                <StaticAlphabet text={'N'} x={25}  y={70} mobile={false} />
                                <StaticAlphabet text={'F'} x={15}  y={90} mobile={false} />
                                <StaticAlphabet text={'C'} x={30}  y={90} mobile={false} />
                                <StaticAlphabet text={'N'}  x={50} y={2} mobile={true}/>
                                <StaticAlphabet text={'R'}  x={3} y={15} mobile={true}/>
                                <StaticAlphabet text={'N'}  x={91} y={20} mobile={true}/>
                                <StaticCoin x={91} y={70} mobile={true} />
                                <StaticAlphabet text={'E'}  x={10} y={35} mobile={true}/>
                                <StaticAlphabet text={'R'}  x={5} y={80} mobile={true}/>
                                <StaticAlphabet text={'E'}  x={50} y={90} mobile={true}/> */}

                  <Grid
                    container
                    className={`contentWrapper ${classes.content1_container}`}
                  >
                    <Grid
                      item
                      md={4}
                      className={classes.section1_textContainer}
                    >
                      <div className={classes.section1_textWrapper}>
                        {parse(floorPlan.HeaderTitle)}
                      </div>
                    </Grid>
                    <Grid item md={8} className={classes.section1_bgWrapper}>
                      {mobileScreen === true ? (
                        <img
                          src={header_mobile}
                          className={classes.section1_bg}
                          alt={floorPlan.HeaderTitle}
                        />
                      ) : (
                        <img
                          src={`${
                            process.env.REACT_APP_BACKEND_URL
                          }${floorPlan.HeaderImage.url.replace(
                            "/uploads/",
                            "/uploads/medium_"
                          )}`}
                          className={classes.section1_bg}
                          alt={floorPlan.HeaderTitle}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {/* <Grid container className={classes.brochure}>
                    <Grid item md={5} className={classes.textContainer}>
                      <div className={classes.ebrochure_wrapper}>
                        <H1>E-BOOKLET</H1>
                        <Button
                          text={"DOWNLOAD"}
                          type={"booklet"}
                          source={`${process.env.REACT_APP_BACKEND_URL}${
                            floorPlan.Ebrochure.url
                          }`}
                        />
                      </div>
                    </Grid>
                  </Grid> */}
                </Grid>

                {floorPlan.Floorplan.map((section, i) => {
                  return i % 2 === 0 ? (
                    <Grid container key={`footer__${section.Name}`}>
                      <Container maxWidth="lg">
                        <Grid container>
                          <Grid
                            item
                            md={5}
                            className={`${classes.textContainer} reverse_991`}
                          >
                            <div className={classes.textWrapperCenter}>
                              <H1>{parse(section.Name)}</H1>
                              {parse(section.Description)}
                              <Button
                                text={"LUXURY BRANDS & FITTINGS"}
                                type={"bedroom_3"}
                                source={floorPlan.Floorplan[0]}
                              />
                            </div>
                          </Grid>
                          <Grid
                            item
                            md={7}
                            className={classes.floorplanContainer}
                          >
                            {/* {mobileScreen === true ? <img src={ThreeBR} className={classes.section1_img} alt="floorplan"/> : <Iframe
                                                    url={`${process.env.REACT_APP_BACKEND_URL}${floorPlan.Floorplan[0].Floorplan3D.url.replace('.zip', '/index.html')}`}
                                                    className={classes.iframeStyle}
                                                    onLoad={ModelLoaded3BR}
                                                /> } */}

                            {mobileScreen === true ? (
                              [
                                mobileActivated == "3br" ? (
                                  //uncomment this
                                  <Iframe
                                    url={`${
                                      process.env.REACT_APP_BACKEND_URL
                                    }${floorPlan.Floorplan[0].Floorplan3D.url.replace(
                                      ".zip",
                                      "_mobile/index.html"
                                    )}`}
                                    className={classes.iframeStyle}
                                    onLoad={ModelLoaded3BR}
                                  />
                                ) : (
                                  // <div className={classes.section1_img}>
                                  //   <img src={ThreeBR} alt="floorplan" />
                                  // </div>
                                  //uncomment this
                                  <div className={classes.section1_img}>
                                    <img
                                      onClick={() => {
                                        setmobileActivated("3br");
                                      }}
                                      src={ThreeBR}
                                      alt="floorplan"
                                    />
                                    <p>Tap to view and rotate 3D model</p>
                                  </div>
                                  // <div className={classes.section1_img}>
                                  //   <img src={ThreeBR} alt="floorplan" />
                                  // </div>
                                ),
                              ]
                            ) : (
                              //uncomment this
                              <Iframe
                                url={`${
                                  process.env.REACT_APP_BACKEND_URL
                                }${floorPlan.Floorplan[0].Floorplan3D.url.replace(
                                  ".zip",
                                  "/index.html"
                                )}`}
                                className={classes.iframeStyle}
                                onLoad={ModelLoaded3BR}
                              />
                              // <div className={classes.section1_img}>
                              //   <img src={ThreeBR} alt="floorplan" />
                              // </div>
                            )}
                            {/* <Floorplan3BR /> */}
                            {/* <img src={img2} className={classes.section1_img} alt="floorplan"/> */}
                          </Grid>
                        </Grid>
                      </Container>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      className={classes.sectionBg}
                      key={`footer__${section.Name}`}
                    >
                      <Container maxWidth="lg">
                        <Grid container>
                          <Grid
                            item
                            md={7}
                            className={classes.floorplanContainer}
                          >
                            {/* <Floorplan2BR /> */}

                            {/* {mobileScreen === true ? (
                              <img
                                src={TwoBR}
                                className={classes.section1_img}
                                alt="floorplan"
                              />
                            ) : (
                              <Iframe
                                url={`${
                                  process.env.REACT_APP_BACKEND_URL
                                }${floorPlan.Floorplan[1].Floorplan3D.url.replace(
                                  ".zip",
                                  "/index.html"
                                )}`}
                                className={classes.iframeStyle}
                                onLoad={ModelLoaded2BR}
                              />
                            )} */}

                            {mobileScreen === true ? (
                              [
                                mobileActivated == "2br" ? (
                                  //uncomment this
                                  <Iframe
                                    url={`${
                                      process.env.REACT_APP_BACKEND_URL
                                    }${floorPlan.Floorplan[1].Floorplan3D.url.replace(
                                      ".zip",
                                      "_mobile/index.html"
                                    )}`}
                                    className={classes.iframeStyle}
                                    onLoad={ModelLoaded2BR}
                                  />
                                ) : (
                                  // <div className={classes.section1_img}>
                                  //   <img src={TwoBR} alt="floorplan" />
                                  // </div>
                                  //uncomment this
                                  <div className={classes.section1_img}>
                                    <img
                                      onClick={() => {
                                        setmobileActivated("2br");
                                      }}
                                      src={TwoBR}
                                      alt="floorplan"
                                    />
                                    <p>Tap to view and rotate 3D model</p>
                                  </div>
                                  // <div className={classes.section1_img}>
                                  //   <img src={TwoBR} alt="floorplan" />
                                  // </div>
                                ),
                              ]
                            ) : (
                              //uncomment this
                              <Iframe
                                url={`${
                                  process.env.REACT_APP_BACKEND_URL
                                }${floorPlan.Floorplan[1].Floorplan3D.url.replace(
                                  ".zip",
                                  "/index.html"
                                )}`}
                                className={classes.iframeStyle}
                                onLoad={ModelLoaded2BR}
                              />
                              // <div className={classes.section1_img}>
                              //   <img src={TwoBR} alt="floorplan" />
                              // </div>
                            )}
                            {/*  <img src={img3} className={classes.section1_img} alt="floorplan"/> */}
                          </Grid>
                          <Grid item md={5} className={classes.textContainer}>
                            <div className={classes.textWrapperCenter}>
                              <H1>{parse(section.Name)}</H1>
                              {parse(section.Description)}
                              <Button
                                text={"LUXURY BRANDS & FITTINGS"}
                                type={"bedroom_2"}
                                source={floorPlan.Floorplan[1]}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Container>
                    </Grid>
                  );
                })}
                <Container maxWidth="lg">
                  <Grid container className={classes.brochure}>
                    <Grid item md={5} className={classes.textContainer}>
                      <div className={classes.ebrochure_wrapper}>
                        <H1>E-Booklet</H1>
                        <Button
                          text={"DOWNLOAD"}
                          type={"booklet"}
                          source={`${process.env.REACT_APP_BACKEND_URL}${
                            floorPlan.Ebrochure.url
                          }`}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </Grid>
            </>
          );
        }}
      </Query>
      <Footer />
    </Wrapper>
  );
};

export default Floorplan;
