import React from "react";
import "./global.scss";
import ReactDOM from "react-dom";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import "./vendor/rxjs";
import { history, store } from "./store";
import { RootProps } from "types/common";
import "semantic-ui-css/semantic.min.css";
import theme from "./utils/theme";

import { ApolloProvider } from "react-apollo";
import client from "utils/apolloClient";

const root = document.getElementById("root");
console.log("ok");
const render = (Component: React.FC<RootProps>) => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Component history={history} />
        </Provider>
      </ThemeProvider>
    </ApolloProvider>,
    root
  );
};
render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
