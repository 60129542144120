import * as React from "react"

function P(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.02 19.17"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path" transform="translate(2.89 1.69)">
          <path
            className="prefix__cls-1"
            d="M1.68 1.41h2.13a5 5 0 012 .37 2.83 2.83 0 011.26 1.07 3.08 3.08 0 01.46 1.7 3.15 3.15 0 01-.46 1.7 3 3 0 01-1.28 1.11 4.63 4.63 0 01-2 .39H1.68zM0 0v15.79h1.68V9.2h2.47a5.87 5.87 0 002.7-.58A4.31 4.31 0 008.61 7a4.71 4.71 0 00.63-2.46 4.5 4.5 0 00-.63-2.42A4.06 4.06 0 006.84.54 6.21 6.21 0 004.15 0z"
          />
        </clipPath>
        <style>{".prefix__cls-1{fill:#99784c}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M1.68 1.41h2.13a5 5 0 012 .37 2.83 2.83 0 011.26 1.07 3.08 3.08 0 01.46 1.7 3.15 3.15 0 01-.46 1.7 3 3 0 01-1.28 1.11 4.63 4.63 0 01-2 .39H1.68zM0 0v15.79h1.68V9.2h2.47a5.87 5.87 0 002.7-.58A4.31 4.31 0 008.61 7a4.71 4.71 0 00.63-2.46 4.5 4.5 0 00-.63-2.42A4.06 4.06 0 006.84.54 6.21 6.21 0 004.15 0z"
            transform="translate(2.89 1.69)"
          />
        </g>
      </g>
    </svg>
  )
}

export default P