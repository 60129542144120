import * as React from "react"

function R(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.34 15.79"
      {...props}
    >
      <g data-name="Layer 2">
        <path
          d="M1.68 1.41h2.13a4.08 4.08 0 012.74.84 2.86 2.86 0 011 2.3 3.15 3.15 0 01-.45 1.7 3 3 0 01-1.29 1.11 4.73 4.73 0 01-2 .39H1.68zM0 0v15.79h1.68V9.24h1.58l5 6.55h2.12L5.15 9.08a5.19 5.19 0 002.07-.71 4.21 4.21 0 001.49-1.59 4.75 4.75 0 00.53-2.27 4.46 4.46 0 00-.63-2.41A4 4 0 006.84.54 6.21 6.21 0 004.15 0z"
          fill="#99784c"
          data-name="Layer 1"
        />
      </g>
    </svg>
  )
}

export default R
