import React from 'react'
import './reverseCoin.css';
import { makeStyles,Theme } from '@material-ui/core';

export interface StyleProps {
    x : number,
    y : number,
    speed : number,
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    root: {
        top: ({y}) => y + '%',
        left: ({x}) =>x + '%',
        position:'absolute',
    },
    speed: {
        animation: ({speed}) => 'rotateReverse ' + speed + 's infinite linear'
    },
}));

const ReverseRotateCoin = (props:any) => {
    const props_style = {
        x:props.x,
        y:props.y,
        speed:props.speed
    }

    const mobile = (mobile:string) => {
        if(mobile) {
            return (
                'mobile'
            )
        } else {
            return 'desktop'
        }
    }

    const classes = useStyles(props_style);
    return (
        <div className={`${classes.root} ${mobile(props.mobile)}`}>
            <div className={`coinReverse ${classes.speed}`}> 
                <div className="side heads">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.69 21.69"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M10.84,0A10.83,10.83,0,0,0,8,21.27V7H5.29V5.33h5.23V7H9.57V21.61a10.69,10.69,0,0,0,1.27.08,10.56,10.56,0,0,0,1.27-.08V7h-.93V5.33H16.4V7H13.72V21.27A10.83,10.83,0,0,0,10.84,0Z"/></g></g></svg>
                </div>
                <div className="side tails">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.69 21.69"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M10.84,0A10.83,10.83,0,0,0,8,21.27V7H5.29V5.33h5.23V7H9.57V21.61a10.69,10.69,0,0,0,1.27.08,10.56,10.56,0,0,0,1.27-.08V7h-.93V5.33H16.4V7H13.72V21.27A10.83,10.83,0,0,0,10.84,0Z"/></g></g></svg>
                </div>
            </div>
        </div>
    )
}

export default ReverseRotateCoin;