import styled from 'styled-components'

const H3 = styled.span`
  font-size: 16px;
  margin-top:0px;
  color:#99784c;
  letter-spacing: 1.2px;
  font-family:'HelveticaNeue-thin'!important;
`;

export default H3;