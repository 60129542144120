import React, { useEffect, useState,useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import TransitionsModal from '../components/Modal'
import { Navigation, Logo } from './Global/global'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { modalActions } from '../actions/modalActions'
// img
import close from '../assets/images/close.png'

type NaviItem = 'overview' | 'location' | 'architecture' | 'amenities' | 'floorplan' | 'endorsement' | 'aboutus' | 'enquiries' | 'thankyou'

// css component
const NavContainer =  styled.section`
    width:100%;
    overflow: auto;
    padding:1em;
    background-color: #fcfdf5;
    z-index:999;
    position:fixed;
    top:-140px;
    transition: 0.5s all ease;
    &.shown {
        top:0;
    }
    @media (max-width: 960px) {
        padding:15px 10px;
        margin: 0;
    }
`;

const LogoWrapper = styled.section`
    width: 180px;
    float: left;
    margin: 0 20px;
    cursor:pointer;
    @media (max-width: 1200px) {
        width: 160px;
        margin: 0;
    }
    @media (max-width: 960px) {
        width: 148px;
        margin: 0;
    }
`;

const MenuWrapper = styled.section`
    float:right
`;

const GlobalNav: React.FC<{ activeItem: NaviItem }> = ({ activeItem }) => {

    const dispatch         = useDispatch();
    const [y, setY]        = useState(window.scrollY);
    const [shown,setShown] = useState('shown');
    const [mobileShown,setMobileShown] = useState('');
    const mobileScreen     = useMediaQuery('(max-width:960px)');
    const modalOpen        = useSelector((state:any) => state.modal.modalOpen)

    //@Desc detect scrollbar direction
    const handleNavigation = useCallback(
        e => {
          const window = e.currentTarget;

          if (y > window.scrollY) {
            setShown('shown')
          } else if ((window.scrollY -y) > 30) {
            setShown('hide')
          }
          if(window.scrollY === 0) {
            setShown('shown')
          }
          setY(window.scrollY);
        }, [y]
    );

    // @Desc didmount and unmount scroll event
    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    // @Desc scroll to Top on change route
    useEffect(() => {
        window.scrollTo(0, 0)

        if(modalOpen) {
            setTimeout(() => {
                window.history.forward()
            }, 0)
            dispatch(modalActions.closeModal());
        }
    }, [activeItem])

    window.onpopstate = e => {
        if(modalOpen) {
            setTimeout(() => {
                window.history.forward()
            }, 0)
            dispatch(modalActions.closeModal());
        }
    }

    const desktop_nav = () => {
        return (
            <MenuWrapper>
                <Navigation activeItem={activeItem} />
            </MenuWrapper>
        );
    }

    const mobile_nav = () => {
        return (
            <>
                <div className="sidebar-menu-wrapper">
                    <div id="sidebar-menu" className={`sidebar-menu  ${mobileShown}`}>
                        <span className="close-btn"  onClick={() => {setMobileShown('')}}>
                            <img src={close} alt="close"/>
                        </span>

                        <div id="menu-list" className="menu-list ul-li-block clearfix">
                            <ul className="clearfix">
                                <li className="menu-item-has-child">
                                    <a onClick={() => dispatch(push('/overview'))} data-toggle="collapse" aria-expanded="false">Overview</a>
                                </li>
                                <li>
                                    <a onClick={() => dispatch(push('/location'))} data-toggle="collapse" aria-expanded="false">Location</a>
                                </li>

                                <li>
                                    <a onClick={() => dispatch(push('/architecture'))} data-toggle="collapse" aria-expanded="false">Architecture</a>
                                </li>
                                <li>
                                    <a onClick={() => dispatch(push('/amenities'))} data-toggle="collapse" aria-expanded="false">Amenities</a>
                                </li>

                                <li>
                                    <a onClick={() => dispatch(push('/floorplan'))} data-toggle="collapse" aria-expanded="false">Floor Plan</a>
                                </li>

                                <li>
                                    <a onClick={() => dispatch(push('/endorsement'))} data-toggle="collapse" aria-expanded="false">Endorsement</a>
                                </li>

                                <li>
                                    <a onClick={() => dispatch(push('/aboutus'))} data-toggle="collapse" aria-expanded="false">About Us</a>
                                </li>

                                <li>
                                    <a onClick={() => dispatch(push('/enquiries'))} data-toggle="collapse" aria-expanded="false">Enquiries</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`overlay ${mobileShown}`} onClick={() => {setMobileShown('')}}></div>
                </div>
            </>
        );
    }
    const hamburger = () => {
        return (
            <>
                <header id="header-section" className={`header-section sticky-header clearfix`}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="mobile-menu-btns float-right ul-li-right">
                                <ul className="clearfix">
                                    <li>
                                        <button type="button" className="menu-btn" onClick={() => {setMobileShown('active')}}>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    }

    return (
        <div>
            <NavContainer className={`navbar ${shown}`}>
                <Container maxWidth="xl">
                    <LogoWrapper onClick={() => dispatch(push('/overview'))}>
                        <Logo />
                    </LogoWrapper>
                    {
                        (mobileScreen === true) ? hamburger() : null
                    }
                    {
                        (mobileScreen === true) ? null : desktop_nav()
                    }
                </Container>
            </NavContainer>
            {
                (mobileScreen === true) ? mobile_nav() : null
            }
            <TransitionsModal />
        </div>
    )
}

export default GlobalNav
