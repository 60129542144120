import React, { useState, useRef, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Thumbs,
  EffectCube,
  Mousewheel,
} from "swiper";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-fade/effect-fade.scss";
import { makeStyles } from "@material-ui/core/styles";
import next from "../assets/images/next.png";
import prev from "../assets/images/prev.png";

// image
import galleryImg1 from "../assets/images/amenities/galleryImg1.png";
import galleryImg2 from "../assets/images/amenities/galleryImg2.png";
import galleryImg3 from "../assets/images/amenities/galleryImg3.png";
import galleryImg4 from "../assets/images/amenities/galleryImg4.png";
import galleryImg5 from "../assets/images/amenities/galleryImg5.png";
import galleryImg6 from "../assets/images/amenities/galleryImg6.png";
import galleryImg7 from "../assets/images/amenities/galleryImg7.png";
import galleryImg8 from "../assets/images/amenities/galleryImg8.png";
import galleryImg9 from "../assets/images/amenities/galleryImg9.png";
import galleryImg10 from "../assets/images/amenities/galleryImg10.png";
import galleryImg11 from "../assets/images/amenities/galleryImg11.png";
import galleryImg12 from "../assets/images/amenities/galleryImg12.png";
import galleryImg13 from "../assets/images/amenities/galleryImg13.png";
import galleryImg14 from "../assets/images/amenities/galleryImg14.png";

SwiperCore.use([Navigation, Pagination, EffectCube, Thumbs, Mousewheel]);

const useStyles = makeStyles(() => ({
  swiperContainer: {
    position: "relative",
  },
  section_left: {
    width: "100%",
    paddingLeft: "0%",
  },
  section_gallery: {
    // height:'85vh',
    maxWidth: "100%",
    maxHeight: "100%",
    paddingLeft: "0%",
    justifyContent: "center",
    "@media (max-width:960px)": {
      height: "auto",
    },
  },
  swiper_navigation: {
    position: "absolute",
    width: "100px",
    zIndex: 12,
    textAlign: "center",
    left: "50%",
    transform: "translate(-100%,-100%)",
    bottom: "-25px",
    "@media (max-width:960px)": {
      width: "225px",
      transform: "translate(-50%,-50%)",
    },
  },
  prev_arrow: {
    position: "absolute",
    left: "-30px",
    top: "3px",
    width: "12px",
    "@media (max-width:960px)": {
      left: "-10px",
      top: "-26px",
      width: "14px",
    },
    "& img": {
      width: "100%",
    },
  },
  next_arrow: {
    position: "absolute",
    right: "-124px",
    top: "2px",
    width: "12px",
    cursor: "pointer",
    "@media (max-width:960px)": {
      width: "14px",
      left: "215px",
      top: "-26px",
    },
    "& img": {
      width: "100%",
    },
  },
  dot: {
    position: "absolute",
    left: "110px",
    top: "6px",
    "@media (max-width:960px)": {
      top: "2px",
    },
  },
  swiper_wrapper: {
    position: "relative",
    cursor: "pointer",
  },
  swiperSlide: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "85vh",
    "@media (max-width:960px)": {
      display: "flex",
      alignItems: "center",
    },
  },
  dotWrapper: {
    position: "absolute",
    top: "9px",
    width: "100% ",
    "@media (max-width:960px)": {
      top: "7px",
    },
  },
  dot: {
    float: "left",
    marginLeft: "5px",
    height: 10,
    width: 10,
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    "&.active": {
      backgroundColor: "#99784c",
    },
    "@media (max-width:960px)": {
      width: 9,
      height: 9,
    },
  },
}));

const SwiperGallery = props => {
  const gallery1 = useRef(null);
  const classes = useStyles();
  const gallery2 = useRef(null);
  const gallery3 = useRef(null);
  const gallery4 = useRef(null);
  const gallery5 = useRef(null);
  const gallery6 = useRef(null);
  const gallery7 = useRef(null);
  const gallery8 = useRef(null);
  const gallery9 = useRef(null);
  const gallery10 = useRef(null);
  const gallery11 = useRef(null);
  const gallery12 = useRef(null);
  const gallery13 = useRef(null);
  const gallery14 = useRef(null);

  const [gallery1state, setGallery1] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery2state, setGallery2] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery3state, setGallery3] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery4state, setGallery4] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery5state, setGallery5] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery6state, setGallery6] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery7state, setGallery7] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery8state, setGallery8] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery9state, setGallery9] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery10state, setGallery10] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery11state, setGallery11] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery12state, setGallery12] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery13state, setGallery13] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });
  const [gallery14state, setGallery14] = useState({
    x: null,
    y: null,
    bottom: null,
    right: null,
  });

  // swiper
  const [activeSlide, setActiveSlide] = useState();
  const [slides, setSlides] = useState();
  const [currentSlideNum, setCurrentSlide] = useState();

  const arrowClick = action => {
    if (action === "prev") {
      activeSlide.slidePrev();
    } else if (action === "next") {
      activeSlide.slideNext();
    }
  };

  const onSlideTo = swiper => {
    const slideIndex = props.slides - 1;
    swiper.slideTo(slideIndex);
  };

  useEffect(() => {
    resizeWindow();
    setTimeout(function() {
      resizeWindow();
    }, 100);
    setTimeout(function() {
      resizeWindow();
    }, 1000);
    setTimeout(function() {
      resizeWindow();
    }, 2000);
    setTimeout(function() {
      resizeWindow();
    }, 3000);
    setTimeout(function() {
      resizeWindow();
    }, 10000);
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  // useEffect(() => {
  //     if(props.slides.length !== undefined) {
  //         setSlides(props.slides.length)
  //     }
  //     console.log(props)
  // },[props.slides]);

  const resizeWindow = () => {
    if (gallery1.current.offsetLeft !== undefined) {
      setTimeout(function() {
        setGallery1({
          x: gallery1.current.offsetLeft,
          y: gallery1.current.offsetTop * 1,
          bottom:
            gallery1.current.offsetTop + gallery1.current.offsetHeight - 30,
          right:
            gallery1.current.offsetLeft + gallery1.current.offsetWidth - 130,
        });
        setGallery2({
          x: gallery2.current.offsetLeft,
          y: gallery2.current.offsetTop * 1,
          bottom:
            gallery2.current.offsetTop + gallery2.current.offsetHeight - 30,
          right:
            gallery2.current.offsetLeft + gallery2.current.offsetWidth - 130,
        });
        setGallery3({
          x: gallery3.current.offsetLeft,
          y: gallery3.current.offsetTop * 1,
          bottom:
            gallery3.current.offsetTop + gallery3.current.offsetHeight - 30,
          right:
            gallery3.current.offsetLeft + gallery3.current.offsetWidth - 130,
        });
        setGallery4({
          x: gallery4.current.offsetLeft,
          y: gallery4.current.offsetTop * 1,
          bottom:
            gallery4.current.offsetTop + gallery4.current.offsetHeight - 30,
          right:
            gallery4.current.offsetLeft + gallery4.current.offsetWidth - 130,
        });
        setGallery5({
          x: gallery5.current.offsetLeft,
          y: gallery5.current.offsetTop * 1,
          bottom:
            gallery5.current.offsetTop + gallery5.current.offsetHeight - 30,
          right:
            gallery5.current.offsetLeft + gallery5.current.offsetWidth - 130,
        });
        setGallery6({
          x: gallery6.current.offsetLeft,
          y: gallery6.current.offsetTop * 1,
          bottom:
            gallery6.current.offsetTop + gallery6.current.offsetHeight - 30,
          right:
            gallery6.current.offsetLeft + gallery6.current.offsetWidth - 130,
        });
        setGallery7({
          x: gallery7.current.offsetLeft,
          y: gallery7.current.offsetTop * 1,
          bottom:
            gallery1.current.offsetTop + gallery7.current.offsetHeight - 30,
          right:
            gallery7.current.offsetLeft + gallery7.current.offsetWidth - 130,
        });
        setGallery8({
          x: gallery8.current.offsetLeft,
          y: gallery8.current.offsetTop * 1,
          bottom:
            gallery8.current.offsetTop + gallery8.current.offsetHeight - 30,
          right:
            gallery8.current.offsetLeft + gallery8.current.offsetWidth - 130,
        });
        setGallery9({
          x: gallery9.current.offsetLeft,
          y: gallery9.current.offsetTop * 1,
          bottom:
            gallery9.current.offsetTop + gallery9.current.offsetHeight - 30,
          right:
            gallery9.current.offsetLeft + gallery9.current.offsetWidth - 130,
        });
        setGallery10({
          x: gallery10.current.offsetLeft,
          y: gallery10.current.offsetTop * 1,
          bottom:
            gallery10.current.offsetTop + gallery10.current.offsetHeight - 30,
          right:
            gallery10.current.offsetLeft + gallery10.current.offsetWidth - 130,
        });
        setGallery11({
          x: gallery11.current.offsetLeft,
          y: gallery11.current.offsetTop * 1,
          bottom:
            gallery11.current.offsetTop + gallery11.current.offsetHeight - 30,
          right:
            gallery11.current.offsetLeft + gallery11.current.offsetWidth - 130,
        });
        setGallery12({
          x: gallery12.current.offsetLeft,
          y: gallery12.current.offsetTop * 1,
          bottom:
            gallery12.current.offsetTop + gallery12.current.offsetHeight - 30,
          right:
            gallery12.current.offsetLeft + gallery12.current.offsetWidth - 130,
        });
        setGallery13({
          x: gallery13.current.offsetLeft,
          y: gallery13.current.offsetTop * 1,
          bottom:
            gallery13.current.offsetTop + gallery13.current.offsetHeight - 30,
          right:
            gallery13.current.offsetLeft + gallery13.current.offsetWidth - 130,
        });
        setGallery14({
          x: gallery14.current.offsetLeft,
          y: gallery14.current.offsetTop * 1,
          bottom:
            gallery14.current.offsetTop + gallery14.current.offsetHeight - 30,
          right:
            gallery14.current.offsetLeft + gallery14.current.offsetWidth - 130,
        });
      }, 100);
    }
  };

  return (
    <div className={classes.swiperContainer}>
      <Swiper
        effect='slide'
        spaceBetween={100}
        centeredSlides={true}
        pagination={{ clickable: true }}
        onInit={swiper => (swiper = setActiveSlide(swiper))}
        onAfterInit={swiper => onSlideTo(swiper)}
        onSlideChange={e => setCurrentSlide(e.realIndex)}
      >
        <>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery1state.x + "px",
                top: gallery1state.y + "px",
              }}
            >
              <p>Clubhouse Exterior (Day View)</p>
            </div>
            <img
              src={galleryImg1}
              ref={gallery1}
              className={`${classes.section_gallery} galleryImg1`}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery1state.right + "px",
                top: gallery1state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery2state.x + "px",
                top: gallery2state.y + "px",
              }}
            >
              <p>Lobby</p>
            </div>
            <img
              src={galleryImg2}
              ref={gallery2}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery2state.right + "px",
                top: gallery2state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery3state.x + "px",
                top: gallery3state.y + "px",
              }}
            >
              <p>Grand Drop-Off</p>
            </div>
            <img
              src={galleryImg3}
              ref={gallery3}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery3state.right + "px",
                top: gallery3state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery4state.x + "px",
                top: gallery4state.y + "px",
              }}
            >
              <p>Sun Deck</p>
            </div>
            <img
              src={galleryImg4}
              ref={gallery4}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery4state.right + "px",
                top: gallery4state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery5state.x + "px",
                top: gallery5state.y + "px",
              }}
            >
              <p>Infinity Pool (Day View)</p>
            </div>
            <img
              src={galleryImg5}
              ref={gallery5}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery5state.right + "px",
                top: gallery5state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery6state.x + "px",
                top: gallery6state.y + "px",
              }}
            >
              <p>Infinity Pool (Evening View)</p>
            </div>
            <img
              src={galleryImg6}
              ref={gallery6}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery6state.right + "px",
                top: gallery6state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery7state.x + "px",
                top: gallery7state.y + "px",
              }}
            >
              <p>Clubhouse Exterior (Evening View)</p>
            </div>
            <img
              src={galleryImg7}
              ref={gallery7}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery7state.right + "px",
                top: gallery7state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery8state.x + "px",
                top: gallery8state.y + "px",
              }}
            >
              <p>Gym</p>
            </div>
            <img
              src={galleryImg8}
              ref={gallery8}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery8state.right + "px",
                top: gallery8state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery9state.x + "px",
                top: gallery9state.y + "px",
              }}
            >
              <p>Starry Light Reflective Pool</p>
            </div>
            <img
              src={galleryImg9}
              ref={gallery9}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery9state.right + "px",
                top: gallery9state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery10state.x + "px",
                top: gallery10state.y + "px",
              }}
            >
              <p>Great Party Lawn</p>
            </div>
            <img
              src={galleryImg10}
              ref={gallery10}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery10state.right + "px",
                top: gallery10state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery11state.x + "px",
                top: gallery11state.y + "px",
              }}
            >
              <p>Function Room</p>
            </div>
            <img
              src={galleryImg11}
              ref={gallery11}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery11state.right + "px",
                top: gallery11state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery12state.x + "px",
                top: gallery12state.y + "px",
              }}
            >
              <p>Infinity Pool (Night View)</p>
            </div>
            <img
              src={galleryImg12}
              ref={gallery12}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery12state.right + "px",
                top: gallery12state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery13state.x + "px",
                top: gallery13state.y + "px",
              }}
            >
              <p>Aqua Gym</p>
            </div>
            <img
              src={galleryImg13}
              ref={gallery13}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery13state.right + "px",
                top: gallery13state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className={classes.swiperSlide}>
            <div
              className={`galleryLabel`}
              style={{
                left: gallery14state.x + "px",
                top: gallery14state.y + "px",
              }}
            >
              <p>B2 Drop-off Lobby</p>
            </div>
            <img
              src={galleryImg14}
              ref={gallery14}
              className={classes.section_gallery}
              alt='gallery'
            />
            <div
              className={`artistWrapper_gallery`}
              style={{
                left: gallery14state.right + "px",
                top: gallery14state.bottom + "px",
              }}
            >
              <p>Artist's Impression</p>
            </div>
          </SwiperSlide>
        </>
      </Swiper>

      <div className={classes.swiper_navigation}>
        <div className={classes.swiper_wrapper}>
          <div
            className={classes.prev_arrow}
            onClick={() => arrowClick("prev")}
          >
            <img src={prev} alt='next_arrow' />
          </div>
          <div className={`${classes.dotWrapper}`}>
            {/* {slides.map((slide,i) => {
                            return (
                                <div className={`${classes.dot} ${(currentSlideNum === i) ? "active" : ""}`}>
                                </div>
                            );
                        })} */}
          </div>
          {/* <div className={classes.dot}>
                        <img src={dot} alt="dot"/>
                    </div> */}
          <div
            className={classes.next_arrow}
            onClick={() => arrowClick("next")}
          >
            <img src={next} alt='next_arrow' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwiperGallery;
