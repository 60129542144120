import React from 'react';
import Wrapper from 'components/Wrapper';
import gql from "graphql-tag";
import GlobalNav from 'components/GlobalNav';
import Grid from '@material-ui/core/Grid';
import Iframe from 'react-iframe';
import { makeStyles } from '@material-ui/core/styles';
import H1 from '../components/h1';
import H3 from '../components/h3';
import SwiperImage from '../components/SwiperImage';
import HeaderBar from '../components/HeaderBar';
import Container from '@material-ui/core/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Query } from 'react-apollo';
import parse from 'html-react-parser';
import bg from '../assets/images/overview/bg.png';
import GlobalFooter from 'components/Footer';
import unique_bg from '../assets/images/arhitecture/unique_bg.png';
import section_mobile from '../assets/images/arhitecture/section1_img_mobile.png'
import LoadingSpinner from '../components/LoadingSpinner'

const QUERY = gql`
  query {
    architecture {
      Name
      HeaderImage {
        url
      }
      HeaderTitle
      HeaderDescription
      GalleryIntro
      Gallery {
        Name
        Description
        Link
        Image {
          url
        }
      }
      SectionGallery {
        Name
        Description
        Link
        Images {
          url
        }
      }
      RotatingModel {
        url
      }
    }
  }
`;

const useStyles = makeStyles(() => ({
    iframeStyle : {
        width: '100vw',
        height: '100vh',
        border: 'none',

        '@media (max-width:960px)': {
            height:'70vh'
        }
    },
    section1_img : {
        width:'100%',
        padding:'0%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)'
    },
    sectionBg : {
        background: `url(${bg}) left top / cover`,
        minHeight:'100vh',
        position:'relative',
        '@media (max-width:960px)': {
            minHeight:'auto'
        }
    },
    sectionUniqueBg: {
        background: `url(${unique_bg}) left top / cover`,
        minHeight:'100vh',
        position:'relative'
    },
    buildingImg :{
        width:'100%',
    },
    textBgWrapper: {
        position:'absolute',
        top:'40%',
        transform: 'translate(0%, -50%)',
        width:'28vw',
        maxWidth:'100%',
        minHeight: '222px',

        '& p' : {
            margin :0,
        },

        '& h1': {
            width: '26vw',

            '@media (max-width:960px)': {
                width: '80%',
                margin: '0 auto',
            }
        },

        '@media (max-width:960px)': {
            position:'relative',
            top:'auto',
            transform: 'none',
            width:'100%',
            textAlign:'left',
            marginBottom:'20px'
        }
    },
    textWrapper : {
        position:'absolute',
        top:'50%',
        transform: 'translate(0%, -50%)',
        maxWidth:'28vw',
        width:'90%',

        '@media (max-width:960px)': {
            position:'relative',
            top:'auto',
            transform: 'none',
            width:'100%',
            textAlign:'left',
            marginBottom:'20px',
            maxWidth:'100%',
        }
    },
    textWrapperRight: {
        position:'absolute',
        top:'50%',
        transform: 'translate(0%, -50%)',
        width:'400px',
        right:0,
        '@media (max-width:960px)': {
            position:'relative',
            top:'auto',
            transform: 'none',
            width:'100%',
            marginBottom:'40px'
        },
    },
    textContainer : {
        position:'relative',
        '@media (max-width:960px)': {
            pointerEvents:'none'
        }
    },
    unique_h3:{
        marginBottom:'0px'
    },
    section_center: {
        textAlign:'center',
        position:'absolute',
        top:'50%',
        transform:'translate(-50%,-50%)',
        left:'50%'
    },
    section_unique: {
        marginTop:'10%',
        textAlign:'center',
        marginBottom:'10%'
    },
    uniqueboxWrapper :{
        textAlign:'left',
        marginTop:'30px'
    },
    sectionBgImgWrapper:{
        justifyContent:'center',
        position:'relative',
        '@media (max-width:960px)': { 
            order:2
        },

        '& img': {
            '@media (min-width:960px)': { 
               position:'absolute',
               top : '50%',
               transform:'translate(0,-50%)'
            }
        }
    },
    sectionImg:{
        position:'relative',
        width: '100%',
        '@media (max-width:960px)': { 
            position:'relative',
            transform:'none',
            top:'auto'
        }
    },
    uniqueCard : {
        '@media (max-width:960px)': { 
            width:'100%'
        }
    },
    swiperContainer: {
        '@media (max-width:960px)': { 
            display:'block',
            paddingBottom:'40px',
            paddingTop:'80px',
        }
    },
    swiperContainerReverse: {
        '@media (max-width:960px)': { 
            display:'block',
            paddingBottom:'40px',
            paddingTop:'80px',
            transform:' scaleY(-1)'
        }
    },
    swiperWrapperReverse :{
        '@media (max-width:960px)': { 
            transform:'scaleY(-1)',
        }
    },
    padding_960 : {
        '@media (max-width:960px)': { 
            paddingTop:'35px'
        }
    },
    contentImage: {
        '@media (max-width:960px)': { 
            paddingTop:'80px',
            paddingBottom:'40px',
            display:'block'
        }
    },
    center_960: {
        '@media (max-width:960px)': { 
            textAlign:'center',
            marginTop:'70px'
        }
    }
}))

const Architecture = () => {
    const classes = useStyles();
    const mobileScreen = useMediaQuery('(max-width:960px)');

    return (
        <Wrapper>
            <GlobalNav activeItem="architecture"/>
            <HeaderBar />

            <Query query={QUERY}>
                {({ data: { architecture }, loading }) => {

                if (loading || !architecture) {
                    return <LoadingSpinner />;
                }

                return(
                    <>
                        <Grid container className={classes.sectionBg}>
                            <Grid container className={`contentWrapper`}>
                                <Grid item md={8} className={classes.sectionBgImgWrapper}>
                                    {mobileScreen === true ? <img src={section_mobile} className={classes.buildingImg} alt={architecture.HeaderTitle} />
                                    :
                                    <img src={`${process.env.REACT_APP_BACKEND_URL}${architecture.HeaderImage.url}`} className={classes.buildingImg} alt={architecture.HeaderTitle} /> }
                                </Grid>
                                <Grid item md={4} className={classes.textContainer}>
                                    <div className={`${classes.textBgWrapper} ${classes.center_960}`}>
                                        {parse(architecture.HeaderTitle)}
                                        <p>{parse(architecture.HeaderDescription)}</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item md={12}>
                                {mobileScreen === true ?
                                    <Iframe
                                    url={`${process.env.REACT_APP_BACKEND_URL}${architecture.RotatingModel.url.replace('.zip', '_mobile/index.html')}`}
                                    className={classes.iframeStyle}
                                />
                                    :
                                    <Iframe
                                    url={`${process.env.REACT_APP_BACKEND_URL}${architecture.RotatingModel.url.replace('.zip', '/index.html')}`}
                                    className={classes.iframeStyle}
                                />
                                }
                            </Grid>
                        </Grid>

                        <Grid container className={`${classes.sectionUniqueBg} ${classes.textContainer}`}>
                            <Container maxWidth="lg">
                                <Grid item md={12} className={classes.section_unique}>
                                    <H1>{parse(architecture.GalleryIntro)}</H1>
                                    <Grid container spacing={2} className={classes.uniqueboxWrapper}>
                                        {architecture.Gallery.map((gallery) => {
                                            return <Grid item md={4} className={classes.uniqueCard}>
                                                <img src={`${process.env.REACT_APP_BACKEND_URL}${gallery.Image.url}`} className={classes.buildingImg} alt={gallery.Name} />
                                                <H3 className={classes.unique_h3}>{parse(gallery.Name)}</H3>
                                                <p>{parse(gallery.Description)}</p>
                                            </Grid>;
                                        })}
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>

                    {architecture.SectionGallery.map((section, i) => {
                        return((i % 2 === 0) ?
                            <Grid container className={`${classes.swiperContainer} contentWrapper`}>
                                <Grid item md={8}>
                                    {section.Images.length > 1 ? 
                                    <div className={`sectionImg_left`}>
                                        <SwiperImage slides={section.Images} direction={'left'}/>
                                    </div> : 
                                    <div className={`sectionImg_leftNomargin`}>
                                        <img src={`${process.env.REACT_APP_BACKEND_URL}${section.Images[0].url}`} className={`img`}  alt='architecture'/>
                                        <div className={`artistWrapper_white`}>
                                            <p>
                                                Artist's Impression
                                            </p>
                                        </div>
                                    </div>}
                                </Grid>
                                <Grid item md={4} className={`${classes.textContainer}`}>
                                    <div className={classes.textWrapper}>
                                        <H1>{parse(section.Name)}</H1>
                                        <p>{parse(section.Description)}</p>
                                    </div>
                                </Grid>
                            </Grid>
                            : 
                            <Grid container className={classes.sectionBg}>
                                <Grid container className={`display_none_991 contentWrapper ${classes.contentImage}`}>
                                    <Grid item md={4} className={`${classes.textContainer}`}>
                                        <div className={`${classes.textWrapperRight}`}>
                                            <H1>{parse(section.Name)}</H1>
                                            <p>{parse(section.Description)}</p>
                                        </div>
                                    </Grid>
                                    <Grid item md={8}>
                                        {section.Images.length > 1 ?
                                        <div className={`sectionImg_right swiper_margin991`}>
                                            <SwiperImage slides={section.Images} direction={'right'}/>
                                        </div>
                                        :
                                        <div className={`sectionImg_right`}>
                                            <img src={`${process.env.REACT_APP_BACKEND_URL}${section.Images[0].url}`} className={`img`}  alt='architecture'/>
                                            <div className={`artistWrapper_white_right`}>
                                                <p>
                                                    Artist's Impression
                                                </p>
                                            </div>
                                        </div>}
                                    </Grid>
                                </Grid>

                                {/* 991 only */}

                                <Grid container className={`contentWrapper display_991 ${classes.contentImage}`}>
                                    <Grid item md={8}>
                                        {section.Images.length > 1 ?
                                        <div className={`sectionImg_right swiper_margin991`}>
                                            <SwiperImage slides={section.Images} direction={'right'}/>
                                        </div>
                                        :
                                        <div className={`sectionImg_right`}>
                                            <img src={`${process.env.REACT_APP_BACKEND_URL}${section.Images[0].url}`} className={`img`}  alt='architecture'/>
                                            <div className={`artistWrapper_white_right`}>
                                                <p>
                                                    Artist's Impression
                                                </p>
                                            </div>
                                        </div>}
                                    </Grid>
                                    <Grid item md={4} className={`${classes.textContainer}`}>
                                        <div className={`${classes.textWrapperRight}`}>
                                            <H1>{parse(section.Name)}</H1>
                                            <p>{parse(section.Description)}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid> );
                        })}
                    </>)
                }}
            </Query>
            <GlobalFooter />
        </Wrapper>
    )
}

export default Architecture