import * as React from "react"

function F(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.89 18.75"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path" transform="translate(2.89 1.48)">
          <path
            className="prefix__cls-13"
            d="M0 0v15.79h1.68V8.63h5.99V7.12H1.68V1.51h6.43V0H0z"
          />
        </clipPath>
        <style>{".prefix__cls-13{fill:#99784c}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-13"
            d="M2.89 1.48v15.79h1.68v-7.16h5.99v-1.5H4.57V2.99H11V1.48H2.89z"
          />
          
        </g>
      </g>
    </svg>
  )
}

export default F
