import { makeStyles,Theme } from '@material-ui/core';
import React from 'react'
import './staticAlphabet.css'
import F from '../Alphabet/F'
import P from '../Alphabet/P'
import R from '../Alphabet/R'
import E from '../Alphabet/E'
import N from '../Alphabet/N'
import C from '../Alphabet/C'
// import C from '../../assets/images/C.png'
// import N from '../../assets/images/N.png'
// import E from '../../assets/images/E.png'
// import P from '../../assets/images/P.png'
// import F from '../../assets/images/F.svg'

export interface StyleProps {
    x : number,
    y : number
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    root: {
        top: ({y}) => y + '%',
        left: ({x}) =>x + '%',
        position:'absolute',
    },
    desktop: {
        display:'inline',
        '@media (max-width:960px)': { 
            display:'none', 
        }
    },
    mobile: {
        display:'none',
        '@media (max-width:960px)': { 
            display:'inline', 
        }
    },
    Alphabet_F: {
        width:'12px',
        '@media (max-width:960px)': { 
            width:'9px',
        }
    },
    Alphabet_P: {
        width:'12px',
        '@media (max-width:960px)': { 
            width:'10px',
        }
    },
    Alphabet_E: {
        width:'12px',
        '@media (max-width:960px)': { 
            width:'9px',
        }
    },
    Alphabet_R: {
        width:'9px',
        '@media (max-width:960px)': { 
            width:'7px',
        }
    },
    Alphabet_N: {
        width:'15px',
        '@media (max-width:960px)': { 
            width:'13px',
        }
    },
    Alphabet_C : {
        width:'15px',
        '@media (max-width:960px)': { 
            width:'13px',
        }
    }
}));

const StaticAlphabet = (props:any) => {
    const props_style = {
        x:props.x,
        y:props.y
    }

    const mobile = (mobile:string) => {
        if(mobile) {
            return (
                'mobile'
            )
        } else {
            return 'desktop'
        }
    }

    // letter type
    const letterType = (type) => {
        switch(type){
            case "R":
                return(
                    <R className={classes.Alphabet_R}/>
                )
            case "F":
               return(
                    // <img src={F} alt="F"/>
                    <F className={classes.Alphabet_F}/>
                )
            case "P":
                return(
                    // <img src={P} alt="P"/>
                    <P className={classes.Alphabet_P}/>
                )
            case "E":
                return(
                    // <img src={E} alt="E"/>
                    <E className={classes.Alphabet_E}/>
                )
            case "N":
                return(
                    <N className={classes.Alphabet_N}/>
                    // <img src={N} alt="N"/>
                )
            case "C":
                return(
                    <C className={classes.Alphabet_C}/>
                    // <img src={C} alt="C"/>
                )
        }
    }

    const classes = useStyles(props_style);
    return (
        <div className={`${classes.root} ${mobile(props.mobile)}`}>
            <div className="coinNumStatic">
                <div className="side heads">
                    {letterType(props.text)}
                </div>
                {/* <div className="side tails">
                    <p>{props.text}</p>
                </div> */}
            </div>
        </div>
    )
}

export default StaticAlphabet;