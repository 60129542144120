import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Footer } from './Global/global';

const useStyles = makeStyles((theme) => ({
    Container: {
        borderTop:'2px solid ' + theme.palette.primary.dark,
    },
    footerWrapper : {
        paddingTop:'20px',
        width: '20%',
        minWidth:'400px',
        float:'right',
        paddingBottom:'20px',
        marginRight: '30px',
        marginLeft: '30px',
        listStyleType: 'none',
        "& h5" : {
            fontSize:'16px',
        },
        "& div" : {
            listStyleType: 'none',
            position: 'relative',
            overflow: 'hidden',
            padding: 0,
            margin: 0,
            height:'80px',
            
            '@media (max-width:960px)': { 
                float:'left',
                width:'100%'
            },
            "& figure:nth-of-type(1)": {
                float:'left',
                width:'35%!important',
                margin:'0',
                position:'absolute',
                bottom:'0',
                "& a img":{
                    '@media (max-width:960px)': {
                        width:'100%',
                    },
                },
                '@media (max-width:960px)': {
                    width:'50%',
                },
            },
            "& figure:nth-of-type(2)": {
                float:'left',
                width:'58%!important',
                position:'absolute',
                bottom:'5px',
                left:'40%',
                margin:'0',
                "& a img":{
                    '@media (max-width:960px)': { 
                        width:'90%',
                    },
                },
            }
        },
        '@media (max-width:960px)': { 
            margin: 0,
            width: '100%',
            paddingTop:'0',
            minWidth:'auto',
        }
    },
    logo_1 : {
        marginRight:'30px'
    },
    footerText : {
        '@media (max-width:960px)': { 
            fontSize:'12px',
        }
    }
}));
const GlobalFooter = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.Container} contentWrapper`}>
            <div className={classes.footerWrapper}>
                <h5 className={classes.footerText}>Another Prestigious Project By:</h5>
                <div>
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

export default GlobalFooter